import React from 'react';
import image1 from '../../images/final-result-1.svg';
import image2 from '../../images/final-result-2.svg';
import image3 from '../../images/final-result-3.svg';
import image4 from '../../images/final-result-4.svg';
import { testLogos } from '../../pages/NeuropsychTest/NeuropsychTest';
import { trimStart } from 'lodash';
const images = [image1, image2, image3, image4];

export const ResultBlock: React.FC<{
  isBlockOdd: boolean;
  index: number;
  forceDesktopView?: boolean;
  title?: string;
  description?: string;
  testName?: string;
}> = ({
  isBlockOdd,
  index,
  forceDesktopView,
  title,
  description,
  testName,
}) => {
  return (
    <div className={forceDesktopView ? 'pdf-content px-[200px] pt-[20px]' : ''}>
      <div
        className={
          forceDesktopView
            ? ` flex flex-row py-[30px] px-[20px] rounded-[10px] items-center gap-[30px] ${
                isBlockOdd ? 'bg-grey2 ' : 'flex-row-reverse'
              }`
            : `flex flex-col md:flex-row py-[30px] px-[20px] md:p-[30px] rounded-[10px] items-center gap-[30px] ${
                isBlockOdd ? 'bg-grey2 ' : 'md:flex-row-reverse'
              }`
        }
      >
        <img
          src={
            (testName ? testLogos[testName] : testLogos.default) ||
            testLogos.default
          }
          alt="test result"
          className={
            forceDesktopView
              ? 'h-full w-fit max-w-[40%]'
              : 'h-[220px] md:h-full w-fit md:max-w-[40%]'
          }
        />
        <div className={'w-fit'}>
          <h6
            className={
              forceDesktopView
                ? 'text-2xl m-0 mb-[10px]'
                : 'text-lg md:text-2xl m-0 mb-[10px]'
            }
          >
            {testName}: {title}
          </h6>
          <p
            className={`${testName === 'USASR' ? 'whitespace-pre-wrap' : ''} ${
              forceDesktopView ? 'text-base m-0' : 'text-sm md:text-base m-0'
            }`}
          >
            {trimStart(description ?? '')}
          </p>
        </div>
      </div>
    </div>
  );
};
