import { Dropdown } from 'primereact/dropdown';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectItem } from 'primereact/selectitem';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ChildRelationship } from '../../pages/RegisterParent/useRegisterParent';

interface Props {
  name: string;
  otherOption?: boolean;
  otherOptionPlaceholder?: string;
  label?: string;
  placeholder?: string;
  options?: SelectItem[];
  containerClassName?: string;
  disabled?: boolean;
}
export const SelectInput: FC<Props> = ({
  name,
  label,
  options = [],
  placeholder,
  otherOption,
  otherOptionPlaceholder,
  containerClassName = '',
  disabled = false,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext();
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherValue, setOtherValue] = useState<string | undefined>(undefined);

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  const checkIfOtherValue = (value: string): boolean => {
    if (!value || value === '') return false;

    const relations = ChildRelationship.map((relation) => relation.value);
    return !relations.includes(value);
  };

  useEffect(() => {
    if (otherOption) {
      if (checkIfOtherValue(control._defaultValues[name])) {
        setOtherValue(control._defaultValues[name]);
        setShowOtherInput(true);
      }
    }
  }, [name, control._defaultValues]);

  return (
    <div
      className={`${
        fieldError ? 'error' : ''
      } label-container form-input-field ${containerClassName} h-fit`}
    >
      <input type="hidden" {...register(name)} />

      <label> {label} </label>
      {(!showOtherInput || !otherOption) && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              id={name}
              disabled={disabled}
              options={
                otherOption
                  ? [...options, { label: 'Other', value: 'Other' }]
                  : options
              }
              onChange={(e) => {
                field.onChange(e.value);
                const isOther = checkIfOtherValue(e.value);
                setShowOtherInput(isOther);
              }}
              onBlur={field.onBlur}
              placeholder={placeholder}
              className="h-full w-full"
            />
          )}
        />
      )}
      {otherOption && showOtherInput && (
        <div className="w-full" style={{ position: 'relative' }}>
          <InputText
            id={`${name}-other`}
            name={name}
            onBlur={(e) => setValue(name, e.currentTarget.value)}
            className="w-full"
            placeholder={otherOptionPlaceholder ?? placeholder}
            defaultValue={otherValue}
          />
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-rounded"
            text
            onClick={() => {
              setValue(name, '');
              setShowOtherInput(false);
            }}
            style={{
              position: 'absolute',
              right: '5px',
              top: '50%',
              transform: 'translateY(-50%)',
              boxShadow: 'none',
            }}
          />
        </div>
      )}

      {fieldError && <div className="input-error-text">{message}</div>}
    </div>
  );
};
