import React from 'react';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';
import { ProgressSpinner } from 'primereact/progressspinner';

interface MessageComponentProps {
  message?: string;
}

const MessageComponent: React.FC<MessageComponentProps> = ({
  message = '',
}) => {
  const parts = message.split(/(LOADING)/g);

  return (
    <div
      className="bg-yellow2 py-[10px] px-[15px] text-black1 max-w-[60vw] md:max-w-[354px] ai-assistant-content"
      style={{ borderRadius: '0px 24px 24px 24px' }}
    >
      {parts.map((part, index) => {
        if (part === 'LOADING') {
          return <ProgressSpinner key={index} className="m-auto w-[50px]" />;
        } else {
          return (
            <Markdown key={index} remarkPlugins={[gfm]}>
              {part}
            </Markdown>
          );
        }
      })}
    </div>
  );
};

export default MessageComponent;
