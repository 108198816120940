import { useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { InputText } from 'primereact/inputtext';

interface Props {
  name: string;
  label?: string;
}
export const PhoneNumberInput: FC<Props> = ({ name, label }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  return (
    <div className={`${fieldError ? 'error' : ''} label-container`}>
      <label className="block">{label}</label>
      <InputText
        keyfilter={/^[0-9+\-()]+$/}
        {...register(name)}
        className="w-full"
        placeholder="+1 (___) ___ - __ - __"
      />
      {fieldError && <div className="input-error-text">{message}</div>}
    </div>
  );
};
