import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../../../constants/messages';
import * as yup from 'yup';
import EmailInput from '../../../components/EmailInput';
import PasswordInput from '../../../components/PasswordInput';
import Input from '../../../components/Input';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { Button } from 'primereact/button';
import { useRegisterStudentContext } from '../../../layouts/RegisterStudentLayout/RegisterStudentContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect } from 'react';
import { useCreateStudentAccount } from './useCreateStudentAccount';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { credentialsService } from '../../../utils/services/credentialsService';
import useAuth from '../../../contexts/AuthContext';
import { emailValidation, passwordValidation } from '../../../types/form';
import { useNavigate } from 'react-router-dom';
import Copyright from '../../../components/Copyright';

interface RegisterFormFields {
  email: string;
  password: string;
  confirmPassword: string;
  fullName: string;
  phone: string;
}

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  confirmPassword: yup
    .string()
    .min(8)
    .max(32)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
    )
    .required(ErrorMessages.Required('Password'))
    .oneOf([yup.ref('password')], 'Passwords must match'),
  fullName: yup.string().required(ErrorMessages.Required('Your First Name')),
  phone: yup
    .string()
    .max(15, ErrorMessages.Max('Phone', 15))
    .required(ErrorMessages.Required('Phone')),
});

export default function CreateStudentAccount() {
  const { studentData, goToNextStep } = useRegisterStudentContext();
  const { loadUser, logout } = useAuth();
  const navigate = useNavigate();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit } = formMethods;

  const { openSnackbar } = useSnackbar();
  const { mutation } = useCreateStudentAccount({
    onSuccess: (response) => {
      openSnackbar({
        message: 'ULURU account has been created',
        type: 'success',
      });
      credentialsService.set({ token: response.accessToken });
      loadUser();
      goToNextStep();
    },
  });
  useEffect(() => {
    formMethods.setValue('email', studentData?.email ?? '');
  }, [studentData, formMethods]);
  const onSubmit: SubmitHandler<RegisterFormFields> = (data) => {
    mutation.mutate({
      email: data.email,
      password: data.password,
      firstName: data.fullName,
      linkCode: studentData?.linkCode ?? '',
      phone: data.phone.replace(/[^0-9+]/g, ''),
    });
  };
  return (
    <div className="relative pb-[80px] bg-white p-[30px] px-[50px] md:p-20 h-full">
      <h2 className="text-2xl md:text-4xl">Create Student Account</h2>
      <p className="text-lg pb-8">Let's make school a game you can win</p>
      {studentData ? (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-[30px]">
              <EmailInput name="email" placeholder="Email" />
              <PasswordInput name="password" placeholder="Password" />
              <PasswordInput
                name="confirmPassword"
                placeholder="Password Confirmation"
              />
              <Input name="fullName" placeholder="Your First Name" />
              <PhoneNumberInput name="phone" />
              <div className="flex justify-between items-center flex-col md:flex-row gap-[30px] md:gap-3">
                <Button
                  type="submit"
                  className="p-[12px] px-[40px] text-center flex justify-center text-sm md:text-base w-full md:w-fit"
                >
                  Join ULURU
                </Button>
                <Button
                  type="button"
                  className="p-0 text-center flex justify-center text-sm md:text-base"
                  link
                  onClick={async () => {
                    await logout();
                    navigate('/login');
                  }}
                >
                  Already have an Account?
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <ProgressSpinner />
      )}
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
    </div>
  );
}
