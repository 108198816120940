import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';
interface UseLinkCodeRequest {
  linkCode: string;
  fullName: string;
  gender: string;
  birthday: Date;
  schoolZipCode: string;
  schoolName: string;
  gradeLevel: string;
}

const useLinkCode = async (data: UseLinkCodeRequest): Promise<boolean> => {
  return httpClient.post(`/link-code/parent/use`, data);
};

export const useUseLinkCode = (options: {
  assignmentId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(useLinkCode, {
    onSuccess: options.onSuccess,
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      handleNetworkError(error);
      options?.onError?.();
    },
  });

  return mutation;
};
