import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';

export interface Motivation {
  uid: string;
  list: string[];
  userUid: string;
  createdAt: string;
  updatedAt: string;
}

export const getMotivationsForStudent = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<Motivation> => {
  const [, studentId] = queryKey;

  return httpClient.get<void, Motivation>(`/motivation/${studentId}`);
};

export const useGetStudentMotivations = ({
  studentId,
}: {
  studentId?: string;
}) => {
  return useQuery(['/motivation', studentId], getMotivationsForStudent, {
    enabled: !!studentId,
  });
};
