import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';
interface UpdateAssignmentRequest {
  subject: string;
  activity?: string;
}

const updateAssignment = async (
  assignmentId: string,
  data: UpdateAssignmentRequest,
): Promise<boolean> => {
  return httpClient.put(`/assignments/${assignmentId}`, data);
};

export const useUpdateAssignment = (options: {
  assignmentId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    (data: UpdateAssignmentRequest) =>
      updateAssignment(options.assignmentId ?? '', data),
    {
      onSuccess: options.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        options?.onError?.();
      },
    },
  );

  return { mutation };
};
