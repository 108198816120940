import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { AxiosError } from 'axios';

const completeAssignment = async (assignmentId: string): Promise<boolean> => {
  return httpClient.put(`/assignments/${assignmentId}/complete`, {});
};

export const useCompleteAssignment = (options: {
  assignmentId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { handleNetworkError } = useSnackbar();

  const mutation = useMutation(
    () => completeAssignment(options.assignmentId ?? ''),
    {
      onSuccess: options.onSuccess,
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        options?.onError?.();
      },
    },
  );

  return { mutation };
};
