import useAuth from '../../contexts/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { useGetVerifyCode } from './useGetVerifyCode';
import { useUseLinkCode } from './useUseLinkCode';
import AddStudentDialog from '../AddStudentDialog/AddStudentDialog';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../contexts/SnackbarContext';

const AddStudentFromCodePopup = () => {
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { data, isLoading } = useGetVerifyCode({
    code: code ?? undefined,
    enabled: user?.role === 'parent',
  });

  const { openSnackbar } = useSnackbar();

  const useLinkCodeMutation = useUseLinkCode({
    onSuccess: () => {
      setSearchParams({});
      setDialogOpen(false);
      openSnackbar({
        message: 'Student added successfully',
        type: 'success',
      });
    },
  });

  useEffect(() => {
    if (data && !isLoading) {
      setDialogOpen(true);
    }
  }, [data]);

  return (
    <>
      {data && (
        <AddStudentDialog
          open={isDialogOpen}
          setOpen={setDialogOpen}
          defaultValues={{
            email: data.email,
            fullName: data.fullName,
            gender: data.gender,
            birthday: new Date(data.birthday),
            schoolZipCode: data.schoolZipCode,
            schoolName: data.schoolName,
            gradeLevel: Number(data.gradeLevel),
          }}
          onSave={(data) => {
            console.log('onSave', data);
            useLinkCodeMutation.mutate({
              linkCode: code ?? '',
              fullName: data.fullName,
              gender: data.gender,
              birthday: data.birthday,
              schoolZipCode: data.schoolZipCode,
              schoolName: data.schoolName,
              gradeLevel: data.gradeLevel.toString(),
            });
          }}
          addMode
        />
      )}
    </>
  );
};

export default AddStudentFromCodePopup;
