import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  StudentVerifyData,
  useVerifyStudentCodeData,
} from './useVerifyStudentCode';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import { useSnackbar } from '../../contexts/SnackbarContext';

interface RegisterProviderProps {
  children: ReactNode;
}

interface RegisterContextProps {
  registrationSteps: {
    active: number;
    total: number;
  };
  goToNextStep: () => void;
  goToPrevStep: () => void;

  studentData?: StudentVerifyData;
}

export const RegisterStudentContext = createContext<RegisterContextProps>({
  registrationSteps: {
    active: 0,
    total: 0,
  },
  goToNextStep: () => {},
  goToPrevStep: () => {},
  studentData: undefined,
});

export const RegisterStudentProvider: React.FC<RegisterProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { code } = useParams<{ code: string }>();
  const { data: studentData, isError } = useVerifyStudentCodeData();
  if (isError) {
    openSnackbar({
      type: 'error',
      message: 'Link is expired',
    });
    navigate('/login');
  }

  const routes = [
    '/student/register/:code',
    '/student/register/:code/signup',
    '/student/register/:code/my-motivation',
    '/student/register/:code/how-to-download',
    '/student/home',
  ];

  const [registrationSteps, setRegistrationSteps] = useState({
    active: 1,
    total: routes.length,
  });

  const { user, isLoading: isLoadingUser } = useAuth();
  useEffect(() => {
    if (!isLoadingUser) {
      if (!user && registrationSteps.active > 2) {
        navigate('/');
      }
      if (user && user.role === UserRole.PARENT) {
        navigate('/');
      }
    }
  }, [isLoadingUser, registrationSteps.active]);

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.split('/').reverse()[0];
    const currentIndex = routes.findIndex((route) => {
      const path = route.split('/').reverse()[0];
      return currentPath === path;
    });

    setRegistrationSteps({
      active: currentIndex + 1,
      total: routes.length - 1,
    });
  }, [location]);

  const goToNextStep = () => {
    let nextStep = registrationSteps.active + 1;
    if (nextStep <= routes.length) {
      // scroll to top
      window.scrollTo(0, 0);
      if (nextStep === 1) {
        nextStep = 2;
      }
      navigate(routes[nextStep - 1].replace(':code', code ?? ''));
    }
  };

  const goToPrevStep = () => {
    const prevStep = registrationSteps.active - 1;
    if (prevStep >= 1) {
      // scroll to top
      window.scrollTo(0, 0);
      navigate(routes[prevStep - 1].replace(':code', code ?? ''));
    }
  };

  return (
    <RegisterStudentContext.Provider
      value={{
        registrationSteps,
        goToNextStep,
        goToPrevStep,

        studentData,
      }}
    >
      {children}
    </RegisterStudentContext.Provider>
  );
};

export function useRegisterStudentContext(): RegisterContextProps {
  return React.useContext(RegisterStudentContext);
}
