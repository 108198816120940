import { FC, useMemo } from 'react';
import AlarmClockIcon from '../../icons/AlarmClockIcon';
import { Link } from 'react-router-dom';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import {
  Assignment,
  AssignmentStatus,
} from '../AssignmentsList/useGetAssignmentList';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { AssignmentTypeBadge } from '../AssignmentTypeBadge/AssignmentTypeBadge';

const itemColors = ['#FAECEE', '#F0EBED', '#FFF8F3', '#ecf3fa', '#F3F9F9'];

export function formatDueDate(dateString: string): string {
  const date = parseISO(dateString);
  const zonedDate = toZonedTime(date, 'UTC');
  return format(zonedDate, 'dd MMM');
}

export const AssignmentsListItem: FC<{
  index: number;
  assignment: Assignment;
  isGrey?: boolean;
  allowToClick?: boolean;
}> = ({ index, assignment, isGrey, allowToClick }) => {
  const { user } = useAuth();

  const dueDate = assignment.dueDate ? formatDueDate(assignment.dueDate) : null;

  const isDueDateInFuture =
    assignment.dueDate && new Date() < new Date(assignment.dueDate);

  const isOverdue = useMemo(() => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const dueDateWithoutTime = new Date(assignment.dueDate).setHours(
      0,
      0,
      0,
      0,
    );
    return (
      assignment.status !== AssignmentStatus.COMPLETED &&
      assignment.status !== AssignmentStatus.SUBMITTED &&
      dueDate &&
      currentDate >= dueDateWithoutTime
    );
  }, [assignment.status, assignment.dueDate]);

  const isCompleted =
    assignment.status === AssignmentStatus.COMPLETED ||
    assignment.status === AssignmentStatus.SUBMITTED;

  return (
    <Link
      className="bg-grey2 w-full flex px-[20px] py-[10px] rounded-[10px] gap-[20px] items-center !text-grey3"
      style={{
        backgroundColor: isGrey
          ? '#EDF4FB'
          : itemColors[index % itemColors.length],
      }}
      to={
        allowToClick
          ? `${
              user?.role === UserRole.PARENT ? '/parent' : '/student'
            }/assignment/${assignment.uid}`
          : '#'
      }
    >
      <img
        src="/images/icon-park-outline-bookmark-three.svg"
        className="bg-blue4 p-[9px] rounded-[10px]"
        alt=""
      />
      <div className="flex flex-col gap-[8px] mr-auto">
        <div className="text-sm">
          {assignment.course?.name || assignment.subject}{' '}
          <AssignmentTypeBadge type={assignment.type} />{' '}
        </div>
        <div
          className={`text-base ${
            assignment.title.includes(' ') ? 'break-words' : 'break-all'
          } ${
            (assignment.status === AssignmentStatus.COMPLETED ||
              assignment.status === AssignmentStatus.SUBMITTED) &&
            isDueDateInFuture
              ? 'line-through'
              : ''
          }`}
        >
          {assignment.title}
        </div>
        <div className="text-xs md:hidden block">{dueDate}</div>
      </div>
      <div className="whitespace-nowrap text-base shrink-0 hidden md:block">
        {dueDate}
      </div>
      <div className="whitespace-nowrap xl:w-[100px] text-center text-black1 font-bold text-xl shrink-0">
        {assignment.grade}
      </div>
      {isCompleted && (
        <div className="bg-green1 p-[10px] rounded-[100%] shrink-0 w-[40px] h-[40px]">
          <i className="pi pi-check text-white h-[17px] ml-[2px]" />
        </div>
      )}
      {!isCompleted && isOverdue && (
        <div className="bg-red2 p-[10px] rounded-[100%] shrink-0 w-[40px] h-[40px]">
          <div className="h-[17px] ml-[1px] mt-[1px]">
            <AlarmClockIcon />
          </div>
        </div>
      )}
      {!isCompleted && !isOverdue && (
        <div className="w-[40px] h-[40px] shrink-0"></div>
      )}
    </Link>
  );
};
