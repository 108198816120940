import React, { FC, useState } from 'react';
import useAuth from '../../contexts/AuthContext';
import ProfileLayout from '../../layouts/ProfileLayout';
import { getHomePath } from '../../utils/getHomePath';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  purchaseStripe,
  useGetSubscriptionDetails,
} from '../ParentSubscription/useParentSubscription';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PlusIcon from '../../icons/PlusIcon';
import {
  useCancelSubscription,
  useGetSubscriptionsList,
} from './useManageSubscriptions';
import { Dialog } from 'primereact/dialog';

import TextareaInput from '../../components/TextareaInput';
import TrashIconWhite from 'src/app/icons/TrashIconWhite';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SubscriptionItem } from '../../components/SubscriptionItem/SubscriptionItem';
import AddStudentDialog from '../../components/AddStudentDialog/AddStudentDialog';

const selectSchema = yup.object().shape({});
const cancelSchema = yup.object().shape({
  reason: yup
    .string()
    .min(1)
    .max(500)
    .required('Please enter a reason for cancellation'),
});

export const CONTACT_PAGE_URL = 'http://theuluru.com/contact/';

export const ManageSubscriptions: FC = () => {
  const { user } = useAuth();

  const { openSnackbar } = useSnackbar();

  const {
    data: subscriptionList,
    refetch: refetchSubscriptionList,
    isLoading: isLoadingSubscriptionList,
  } = useGetSubscriptionsList();

  const [selectedStudents, setSelectedStudents] = useState<{
    [uid: string]: boolean;
  }>({});

  const { data: subscriptionDetails } = useGetSubscriptionDetails();
  const [createStudentDialog, setCreateStudentDialog] =
    useState<boolean>(false);
  const [cancelSubscriptionDialog, setCancelSubscriptionDialog] =
    useState<boolean>(false);

  const selectFormMethods = useForm({
    resolver: yupResolver(selectSchema),
    defaultValues: {},
  });
  const formMethods = useForm({
    resolver: yupResolver(cancelSchema),
    defaultValues: {},
  });
  const { handleSubmit } = formMethods;

  const { mutation } = useCancelSubscription({
    onSuccess: () => {
      setCancelSubscriptionDialog(false);
      refetchSubscriptionList();
      setSelectedStudents({});
    },
  });

  const onSubmit: SubmitHandler<{ reason: string }> = (data) => {
    mutation.mutate({ selectedStudents, reason: data.reason });
  };

  const purchaseSubscription = async (userUid: string) => {
    const result = await purchaseStripe(
      subscriptionDetails?.stripePublicKey ?? '',
      {
        [userUid]: true,
      },
      '/manage-subscriptions',
      '/register/invitations',
    );
    if (!result) {
      openSnackbar({
        type: 'error',
        message:
          'There are some issues with the payment gateway, please contact the administrator',
      });
    }
  };

  const handleCheckboxChange = (uid: string, checked: boolean) => {
    setSelectedStudents((prev) => ({ ...prev, [uid]: checked }));
  };

  return (
    <ProfileLayout
      breadcrumbValues={[
        {
          label: 'Home',
          href: getHomePath(user),
        },
        { label: 'Subscription management', href: '#' },
      ]}
    >
      <div className="md:mt-[40px] mb-[50px] flex justify-between items-center">
        <h3 className="m-0 text-2xl md:text-4xl text-header-color">
          Subscription management
        </h3>
      </div>
      <div className="flex flex-col md:flex-row gap-[20px]">
        <FormProvider {...selectFormMethods}>
          {isLoadingSubscriptionList ? (
            <ProgressSpinner className="m-auto" />
          ) : (
            <form className=" w-full">
              {subscriptionList.map((sub, i) => (
                <SubscriptionItem
                  key={i}
                  subscription={sub}
                  selectedStudents={selectedStudents}
                  refundDays={subscriptionDetails?.refundDays ?? 0}
                  handleCheckboxChange={handleCheckboxChange}
                  purchaseSubscription={purchaseSubscription}
                />
              ))}
              <div
                className="student-add bg-grey rounded-lg py-[15px] mb-2 text-center cursor-pointer flex items-center justify-center gap-[10px]"
                onClick={() => {
                  setCreateStudentDialog(true);
                }}
              >
                <div>Subscribe new student</div>
                <PlusIcon />
              </div>
              <Accordion className="elula-accordion mt-7">
                <AccordionTab
                  className="elula-accordion-tab"
                  header="Cancellation / Refund Policy"
                >
                  <p>
                    We understand that circumstances may change, and the need to
                    cancel your subscription may occur. Please find our
                    cancellation policy below:
                  </p>
                  <p>
                    <b>Cancellation Window:</b> You can cancel your subscription
                    at any time, and the cancellation will be applied after
                    current billing period.
                  </p>
                  <p>
                    <b>How to Cancel:</b> To cancel your subscription, log in to
                    your ULURU account and navigate to the subscription settings
                    in profile menu.{' '}
                  </p>
                  <p>
                    <b>Auto-Renewal:</b> By default, subscriptions are set to
                    auto-renew at the end of each billing period.
                  </p>
                  <p className="mb-0">
                    <b>Contact Support:</b> If you have any questions or face
                    issues while canceling your subscription, please reach out
                    to our customer support team for assistance{' '}
                    <a
                      href={CONTACT_PAGE_URL}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      Contact
                    </a>{' '}
                  </p>
                </AccordionTab>
              </Accordion>
              <a
                href="javascipt:void(0)"
                className={`text-lg mt-7 ${
                  !Object.values(selectedStudents).filter((s) => s).length &&
                  'link-disabled'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setCancelSubscriptionDialog(true);
                }}
              >
                Cancel Subscription
              </a>
            </form>
          )}
        </FormProvider>
        <AddStudentDialog
          open={createStudentDialog}
          setOpen={setCreateStudentDialog}
        />
        <Dialog
          header="Cancel subscription"
          visible={cancelSubscriptionDialog}
          onHide={() => setCancelSubscriptionDialog(false)}
          blockScroll={true}
          contentStyle={{ overflow: 'auto' }}
        >
          <p className={'text-center'}>
            Are you sure you want to stop the ULURU subscription for the next
            billing period?
          </p>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextareaInput
                key={'reason'}
                name={'reason'}
                label={'Reason for cancellation'}
              />
              <button
                type="submit"
                className="p-button p-component float-right p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
              >
                Cancel Subscription
                <div className={'inline ml-1 relative top-1'}>
                  <TrashIconWhite />
                </div>
              </button>
            </form>
          </FormProvider>
        </Dialog>
      </div>
    </ProfileLayout>
  );
};
