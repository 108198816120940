import { httpClient } from '../../api/http/http-client';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export interface StudentVerifyData {
  birthday: string;
  email: string;
  firstName?: string;
  fullName: string;
  gender: string;
  gradeLevel: string;
  linkCode: string;
  phone?: string;
  schoolName: string;
  schoolZipCode?: string;
}

export const useVerifyStudentCode = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<StudentVerifyData> => {
  const [, code] = queryKey;
  return httpClient.post<void, StudentVerifyData>('/student/verify/code', {
    linkCode: code,
  });
};

export const useVerifyStudentCodeData = () => {
  const { code } = useParams<{ code: string }>();

  return useQuery(['/student/verify/code', code], useVerifyStudentCode, {
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
