import { FormProvider, useForm } from 'react-hook-form';
import EmailInput from '../../../components/EmailInput';
import Input from '../../../components/Input';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useSchoologyLogin } from './useLogin';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailValidation, passwordValidation } from '../../../types/form';
import Copyright from '../../../components/Copyright';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { AxiosError } from 'axios';

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  rememberMe: yup.boolean(),
  realm: yup.string().required(),
  realm_id: yup.string().required(),
  app_id: yup.string().required(),
  is_ssl: yup.string().required(),
});

export const SchoologyLogin: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const realm = queryParams.get('realm');
  const realm_id = queryParams.get('realm_id');
  const app_id = queryParams.get('app_id');
  const is_ssl = queryParams.get('is_ssl');

  if (!realm || !realm_id || !app_id) {
    navigate('/');
  }

  const { handleNetworkError } = useSnackbar();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rememberMe: false,
      realm: realm ?? undefined,
      realm_id: realm_id ?? undefined,
      app_id: app_id ?? undefined,
      is_ssl: is_ssl ?? undefined,
    },
  });
  const { handleSubmit } = formMethods;

  const { mutation: loginMutation } = useSchoologyLogin({
    onSuccess: (data) => {
      if (data.url) {
        window.location.href = data.url;
      }
    },
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      if (error?.response?.status === 400) {
        const message =
          (error.response?.data?.message as string) ||
          'The email or password is incorrect. Please try again';
        formMethods.setError('email', {
          type: 'manual',
        });
        formMethods.setError('password', {
          type: 'manual',
          message,
        });
      } else {
        handleNetworkError(error);
      }
    },
  });

  return (
    <div>
      <div className="pt-0 md:pt-20 p-20">
        <h2 className="text-2xl md:text-4xl text-center md:text-start my-[30px]">
          Schoology Integration Login
        </h2>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit((data) => {
              loginMutation.mutate({
                email: data.email,
                password: data.password,
                realm: data.realm,
                realm_id: data.realm_id,
                app_id: data.app_id,
                is_ssl: data.is_ssl,
              });
            })}
          >
            <div className="flex flex-col gap-[40px]">
              <EmailInput
                name="email"
                placeholder="Your Email"
                inputClassName="bg-yellow1"
              />
              <Input
                name="password"
                inputType="password"
                placeholder="Enter Password"
                inputClassName="bg-yellow1"
              />
            </div>

            <div className="flex flex-col md:flex-row justify-between md:items-center items-start md:mt-[30px] mt-[20px]">
              <Button
                type="submit"
                className="w-full md:w-auto mt-4 md:mt-0 justify-center py-[12px] px-[47px] leading-7 md:text-base text-sm"
              >
                Sign In
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      <Copyright className="absolute left-0 md:left-[40%] right-0 bottom-0 p-[30px]" />
    </div>
  );
};
