import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ResultBlock } from '../../components/ResultBlock/ResultBlock';
import logo from '../../images/logo.png';
import { PdfTestResult } from '../../components/PdfTestResult/PdfTestResult';
import { ResultStatus, ResultType } from './useGetTestResult';
import { ProgressSpinner } from 'primereact/progressspinner';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import Breadcrumbs from '../../components/Breadcrumbs';
import { GendersMap } from '../../components/RegisterStudentForm/RegisterStudentForm';
import { getHomePath } from '../../utils/getHomePath';
import LoadingProgress from '../../components/LoadingProgress/LoadingProgress';
import BackgroundImage from '../../components/BackgroundImage';
import InfoComponent from '../../components/InfoComponent';
import { useGetTestResultByUid } from './useGetTestResultByUid';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import canvasSize from 'canvas-size';

export function processText(text: string): {
  title: string;
  description: string;
} {
  if (!text?.trim?.()) {
    return { title: '', description: '' };
  }

  const sentences = text.match(/[^.!?]+[.!?]/g) || [];

  const title = sentences.shift() ?? '';

  const description = sentences.join(' ');

  return { title, description };
}

function calculateAge(birthDateString?: string): string {
  try {
    if (!birthDateString) return '';
    const birthDate = new Date(birthDateString);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return String(age);
  } catch (e) {
    return '';
  }
}

const BackToHomeScreenButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { user } = useAuth();
  return (
    <Button
      label={
        user?.role === UserRole.ADMIN
          ? 'Back to Admin Panel'
          : 'Back to homescreen'
      }
      icon="pi pi-arrow-left"
      outlined
      className="back-to-home-btn text-sm md:text-base"
      onClick={onClick}
    />
  );
};

export default function TestResult() {
  const navigate = useNavigate();
  const { _studentUserId, resultUid } = useParams();
  const { user, loadUser } = useAuth();
  const pdfViewRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useState<ResultStatus>();

  const { isLoading, data: testResultData } = useGetTestResultByUid({
    resultUid,
  });

  const isFinalRecommendation = testResultData?.type === ResultType.FINAL;

  useEffect(() => {
    if (status !== testResultData?.status) {
      if (
        status === ResultStatus.GENERATING &&
        (testResultData?.status === ResultStatus.READY ||
          testResultData?.status === ResultStatus.PARTIALLY_READY)
      ) {
        // load user
        loadUser();
      }
      setStatus(testResultData?.status);
    }
  }, [testResultData?.status]);
  const generatePdfClick = async () => {
    if (pdfViewRef?.current) pdfViewRef.current.style.display = 'block';

    const elements = document.querySelectorAll('.pdf-content'); // Select all elements with the 'pdf-content' class
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: 'a4',
    });

    const maxCanvasSize = await canvasSize.maxArea({ usePromise: true });

    let yPos = 0; // Initialize y position for elements

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement; // Type assertion to HTMLElement

      // Calculate scale to not exceed max canvas dimensions
      const scaleX = maxCanvasSize.width / element.offsetWidth;
      const scaleY = maxCanvasSize.height / element.offsetHeight;
      const scale = Math.min(scaleX, scaleY, 1); // Ensure scale is not above 1

      const canvas = await html2canvas(element, { scale });

      // Convert the canvas to an image data URL
      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      // Adjust the image's dimensions to fit the PDF page
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Check if the current yPos and element's height will fit on the page, if not, add a new page
      if (yPos + pdfHeight > pdf.internal.pageSize.getHeight()) {
        pdf.addPage();
        yPos = 0; // Reset yPos for the new page
      }

      pdf.addImage(imgData, 'JPEG', 0, yPos, pdfWidth, pdfHeight);
      yPos += pdfHeight; // Increment yPos by the height of the current element
    }

    // Save the PDF
    try {
      await pdf.save(
        isFinalRecommendation
          ? 'Uluru_FinalRecommendation.pdf'
          : 'Uluru_IntermediateRecommendation.pdf',
        { returnPromise: true },
      );
    } catch (e) {
      console.error(e);
    }

    if (pdfViewRef?.current) pdfViewRef.current.style.display = 'none';
  };

  const parsedSummary = processText(testResultData?.summaryText ?? '');

  if (isLoading) {
    return (
      <div className="flex flex-col items-center">
        <ProgressSpinner className="mt-[150px] mx-auto w-[170px] h-[170px]" />
      </div>
    );
  }

  if (user?.role === UserRole.PARENT && !testResultData) {
    return (
      <div className="flex flex-col gap-[50px] items-center pt-[150px] px-[50px] max-w-[690px] m-auto">
        <h3 className="text-center">
          Thank you for completing the parent part of Mental Health Check-In.
          Uluru will provide the final recommendation once your child completes
          their part of the test. Please assist your child and help them to
          proceed.
        </h3>
        <BackToHomeScreenButton onClick={() => navigate(getHomePath(user))} />
      </div>
    );
  }

  if (!testResultData || testResultData?.status === ResultStatus.GENERATING) {
    return (
      <div className="flex flex-col items-center">
        <ProgressSpinner className="mt-[150px] mx-auto w-[170px] h-[170px]" />
        <h3 className="mb-[30px] p-[20px] text-center">
          Please wait until Uluru generates the recommendation based on the
          Mental Health Check-In results.
        </h3>
        <BackToHomeScreenButton onClick={() => navigate(getHomePath(user))} />
      </div>
    );
  }

  let totalTestsNumber =
    ((testResultData?.aiServiceMapping &&
      Object.keys(testResultData?.aiServiceMapping)?.length) ||
      0) + (testResultData?.results?.length || 0);

  let completedTestsNumber = testResultData?.results?.length || 0;

  if (testResultData.summaryText) {
    totalTestsNumber += 1;
    completedTestsNumber += 1;
  }
  const resultData = user?.students?.find?.(
    (s) => s.uid === testResultData.student.uid,
  )?.testResultData;
  return (
    <>
      <div
        style={{
          breakInside: 'avoid-page',
          pageBreakInside: 'avoid',
        }}
        className="overflow-x-hidden"
        id="content-id"
      >
        <header className="relative py-[50px] md:py-[71px] px-[17px] lg:px-[230px] flex gap-[30px] items-center">
          <BackgroundImage
            desktopImage={'/images/pebble-background/desktop-4.png'}
            mobileImage={'/images/pebble-background/mobile-4.png'}
          />
          <img src={logo} className="w-[88px] md:w-[172px]" alt="" />
          <div className="flex flex-col gap-[10px]">
            <h1 className="text-2xl md:text-4xl text-header-color m-0">
              {isFinalRecommendation
                ? 'Great job!'
                : 'Preliminary findings from the recent Mental Health Check-In'}
            </h1>
            <p className="m-0 text-sm md:text-base">
              {isFinalRecommendation
                ? `
              Your Mental Health Check-In results show your remarkable
              resilience and potential. Your determination and effort are truly
              commendable.
              `
                : "It's important to note that not all test questions were answered, which may impact the comprehensiveness of our evaluation."}
            </p>
          </div>
        </header>
        <div className="bg-white py-0 px-[17px] lg:px-[230px]">
          <Breadcrumbs
            className="py-[30px]"
            values={[
              {
                label: 'Home',
                href: getHomePath(user),
              },
              {
                label: isFinalRecommendation
                  ? 'Final recommendation'
                  : 'Intermediate Recommendation',
                href: '#',
              },
            ]}
          />
          {!isFinalRecommendation &&
          resultData?.finalResult?.isTestResultAvailable ? (
            <Button
              label={'Go to final result'}
              icon="pi pi-arrow-right"
              iconPos="right"
              outlined
              className="back-to-home-btn text-sm md:text-base"
              onClick={() => {
                navigate(
                  `/${
                    user?.role === UserRole.PARENT ? 'parent' : 'student'
                  }/neuro-test/result/${resultData?.finalResult?.resultUid}`,
                );
              }}
            />
          ) : (
            false
          )}
          <div className="flex justify-between py-[30px] items-center">
            <div className="flex gap-[20px] items-center">
              <img
                src="/images/default-profile-pic.svg"
                alt=""
                className="w-[48px] h-[48px]"
              />
              <div>
                <div className="text-lg md:text-2xl font-bold text-header-color">
                  {testResultData?.student?.fullName || ''}
                </div>
                <div className="text-sm md:text-base">
                  {calculateAge(testResultData?.student?.birthday)} Years
                  {testResultData?.student?.gender
                    ? `, ${GendersMap[testResultData?.student?.gender]}`
                    : ''}
                </div>
              </div>
            </div>
            <div>
              <Button
                className="text-sm md:text-base py-[12px] px-[20px] flex gap-[10px] items-center font-normal rounded-[8px]"
                onClick={generatePdfClick}
              >
                <span className="block md:hidden">PDF</span>
                <span className="hidden md:block">Download PDF</span>
                <i className="pi pi-download" />
              </Button>
            </div>
          </div>
          <div className="pb-[60px]">
            <span className="text-grey1">
              Uluru is generating the recommendation for you. It might take
              couple of minutes
            </span>
            <LoadingProgress
              label={'Results of'}
              current={completedTestsNumber}
              total={totalTestsNumber}
            />
          </div>
          {!isFinalRecommendation && (
            <InfoComponent
              text={
                'The final Mental Health Check-In recommendation will be available on  homescreen when  both student and parent complete their test.'
              }
            />
          )}
          {parsedSummary && (
            <div className="pb-[60px]">
              <h2 className="text-2xl md:text-4xl text-header-color m-0 mb-[10px]">
                {parsedSummary?.title}
              </h2>
              <p className="text-sm md:text-base m-0">
                {parsedSummary?.description}
              </p>
            </div>
          )}

          <div className="flex flex-col gap-[30px] md:gap-[60px]">
            {testResultData?.results.map?.((r, index) => {
              const parsedText = processText(r.resultText || '');
              return (
                <ResultBlock
                  isBlockOdd={!!(index % 2)}
                  key={index}
                  index={index}
                  testName={r.testSubName}
                  title={parsedText.title}
                  description={parsedText.description}
                />
              );
            })}
          </div>
          <div className="flex justify-center py-[30px]">
            <BackToHomeScreenButton
              onClick={() => navigate(getHomePath(user))}
            />
          </div>
        </div>

        <footer className="flex flex-wrap py-[30px] md:py-[60px] px-[30px] pb-[10px] text-center bg-yellow2">
          <div className="w-full md:w-1/2 ">
            <h4 className="mb-[10px] mt-0 text-xl md:text-2xl">
              Keep up the positive mindset and continue to follow the above
              recommendations:
            </h4>
            <p className="m-0 text-sm md:text-base">
              Together, we'll unlock even greater achievements on your path to
              wellness and success.
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-center items-center pt-[30px] md:pt-0">
            <div className="flex flex-col">
              <h6 className="mb-[10px] mt-0 text-lg">Need help? Contact</h6>
              <h6 className="mb-[10px] mt-0">
                <a
                  className="text-lg font-bold"
                  href="mailto:help@theuluru.com"
                >
                  help@theuluru.com
                </a>
              </h6>
            </div>
          </div>
        </footer>
      </div>
      <PdfTestResult
        ref={pdfViewRef}
        summary={parsedSummary}
        resultItems={testResultData?.results || []}
        isFinalRecommendation={isFinalRecommendation}
        studentName={testResultData?.student?.fullName || ''}
        studentAge={calculateAge(testResultData?.student?.birthday)}
        studentGender={
          testResultData?.student?.gender &&
          GendersMap[testResultData?.student?.gender]
        }
      />
    </>
  );
}
