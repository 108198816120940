import { httpClient } from '../../api/http/http-client';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

export interface MessageEntity {
  uid: string;
  studentUid?: string;
  message: string;
  author: AuthorMessage;
  createdAt: Date;
}

export enum AuthorMessage {
  STUDENT = 'student',
  AI = 'ai',
}

export const isUpdating = (messages: MessageEntity[]) => {
  const lastMessage = messages[0];
  if (!lastMessage) {
    return false;
  }
  return (
    lastMessage.author === AuthorMessage.STUDENT
    // &&
    // new Date(lastMessage.createdAt).getTime() >
    //   new Date().getTime() - 1000 * 60 * 5
  );
};

export const getMessages = async ({
  queryKey,
}: {
  queryKey: (string | number | undefined)[];
}): Promise<MessageEntity[]> => {
  const [, studentUid, page, limit] = queryKey;
  let url = `/ai-assistant/messages?limit=${limit}&page=${page}`;
  if (studentUid) {
    url += `&studentUid=${studentUid}`;
  }
  return httpClient.get(url);
};

export const sendMessageToAssistant = async (
  message: string,
  studentUid: string | undefined,
): Promise<void> => {
  let url = `/ai-assistant/message`;
  if (studentUid) {
    url += `?studentUid=${studentUid}`;
  }
  return httpClient.post(url, { message });
};

export const useChatWindow = ({
  studentUid,
  page = 1,
  limit = 1000,
}: {
  studentUid: string | undefined;
  page?: number;
  limit?: number;
}) => {
  const [keepLoading, setKeepLoading] = useState(true);
  const useQueryResult = useQuery(
    [`/ai-assistant/messages`, studentUid, page, limit],
    getMessages,
    {
      refetchInterval: keepLoading ? 3000 : false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );

  useEffect(() => {
    setKeepLoading(!(useQueryResult.data && !isUpdating(useQueryResult.data)));
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
  };
};
