import React, { FC } from 'react';
import { Password } from 'primereact/password';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  hideFeedback?: boolean;
}
export const PasswordInput: FC<Props> = ({
  name,
  label,
  placeholder,
  hideFeedback = false,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  return (
    <div className={`${fieldError ? 'error' : ''} label-container`}>
      <label className="hidden md:block">{label}</label>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Password
            {...field}
            onChange={(e) => {
              setValue(name, e.target.value);
              field.onChange(e);
            }}
            placeholder={placeholder}
            toggleMask
            feedback={!hideFeedback}
            strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+<>?])[A-Za-z\d!@#$%^&*()_+<>?]{8,}$"
          />
        )}
      />
      {fieldError && (
        <div className="input-error-text !relative !mb-[-20px]">{message}</div>
      )}
    </div>
  );
};
