import React, { FC, useState } from 'react';
import TrashIcon from '../../../icons/TrashIcon';
import { Dialog } from 'primereact/dialog';
import { SchoologyTokenStatus } from './useVerify';
import { unlinkSchoologyLogin, useSchoology } from './useSchoology';

export interface SchoologyProps {
  studentUid: string;
}

export const SchoologyComponent: FC<SchoologyProps> = ({ studentUid }) => {
  const { data: schoologyLink, refetch } = useSchoology({ studentUid });
  const [unlinkStudentVisibility, setUnlinkStudentVisibility] =
    useState<boolean>(false);
  const toggleUnlinkPopup = (open = false) => {
    setUnlinkStudentVisibility(open);
  };

  const unlinkSchoology = async (studentUid: string) => {
    await unlinkSchoologyLogin(studentUid);
    toggleUnlinkPopup(false);
    await refetch();
  };

  return (
    <>
      {schoologyLink?.isLinked &&
        schoologyLink?.status === SchoologyTokenStatus.UNVERIFIED && (
          <p>
            Please complete verification process on Schoology portal or contact
            support.
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              //eslint-disable-next-line no-script-url
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                toggleUnlinkPopup(true);
              }}
            >
              <div className={'inline ml-1 relative top-1'}>
                <TrashIcon />
              </div>{' '}
              Unlink
            </a>
          </p>
        )}
      {!schoologyLink?.isLinked && (
        <a
          href={process.env.REACT_APP_SCHOOLOGY_APP_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <button
            type="submit"
            className="p-button green-700 p-component p-[12px] px-[40px] text-center text-sm md:text-base mt-2"
          >
            Link Schoology account
          </button>
        </a>
      )}
      {schoologyLink?.isLinked && (
        <>
          <p className={'mt-2'}>
            This Uluru account has been already connected to Schoology account.{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              //eslint-disable-next-line no-script-url
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                toggleUnlinkPopup(true);
              }}
            >
              <div className={'inline ml-1 relative top-1'}>
                <TrashIcon />
              </div>{' '}
              Unlink
            </a>
          </p>
          <Dialog
            header="Unlink Confirmation"
            visible={unlinkStudentVisibility}
            onHide={() => toggleUnlinkPopup(false)}
            blockScroll={true}
            contentStyle={{ overflow: 'auto' }}
          >
            <p className={'text-center'}>
              Are you sure want to unlink the Uluru account from Schoology
              account?
            </p>
            <button
              type="button"
              onClick={() => {
                unlinkSchoology(studentUid);
              }}
              className="p-button p-component float-right p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => {
                toggleUnlinkPopup(false);
              }}
              className="p-button p-component float-left p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Cancel
            </button>
          </Dialog>
        </>
      )}
    </>
  );
};
