import React, { FC, useState } from 'react';
import SearchInput from '../../components/SearchInput';
import { useGetFamilyAccounts } from './useGetFamilyAccounts';
import { Paginator } from 'primereact/paginator';
import { Parent } from '../../types/Parent';
import {
  getStatusLabel,
  StripeStatus,
} from '../ManageSubscription/useManageSubscriptions';
import DateIcon from '../../icons/DateIcon';
import { NavLink } from 'react-router-dom';

export const StatusItemClassName =
  'text-[10px] md:text-base h-fit px-[10px] md:px-[20px] py-[1px] md:py-[5px] rounded-[5px] md:rounded-[10px] min-w-[75px] md:min-w-[130px] text-center';
const ParentItem: FC<{ parent: Parent }> = ({ parent }) => {
  return (
    <NavLink
      className="flex flex-col md:flex-row justify-between py-[10px] px-[20px] text-sm text-black1 w-full cursor-pointer"
      style={{
        borderBottom: '1px solid #EDF0F6',
      }}
      to={`/admin/family-accounts/${parent.uid}`}
    >
      <div className="grow md:w-1/2 flex">
        <div className="h-fit my-auto">
          <div className="text-[10px] mb-[5px] md:hidden block">
            Parent name / email
          </div>
          <div className="flex items-center gap-[10px]">
            <span className="text-sm md:text-base text-black1 font-bold break-all">
              {parent.firstName + ' '}
              {parent.lastName}
            </span>
            {parent?.user?.verified ? (
              <span className="bg-green4 text-green5 text-[10px] h-fit px-[10px] py-[0px] rounded-[5px]">
                Verified
              </span>
            ) : (
              <span className="bg-red3 text-red4 text-[10px] h-fit px-[10px] py-[0px] rounded-[5px]">
                Not Verified
              </span>
            )}
          </div>
          <div className="text-sm md:text-base text-grey3 break-all">
            {parent?.user?.email}
          </div>
        </div>
      </div>
      <div className="grow md:w-1/2 flex flex-col gap-[10px]">
        <div className="text-[10px] mt-[10px] mb-[-5px] md:hidden block">
          Student name / email
        </div>
        {parent?.students?.map?.((student, index: number) => {
          const subscription = student?.subscription;

          return (
            <div
              key={student.uid}
              className="flex justify-between items-center w-full"
              style={{
                borderBottom:
                  index < (parent?.students?.length || 1) - 1
                    ? '1px solid #EDF0F6'
                    : 'none',
              }}
            >
              <div className="flex flex-col">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.fullName}
                </div>
                <div className="text-sm md:text-base text-grey3 break-all">
                  {student?.user?.email}
                </div>
              </div>
              <div className="flex flex-col flex-wrap gap-[5px]">
                <div
                  className={`${
                    subscription?.status === StripeStatus.ACTIVE
                      ? 'bg-green4 text-green5'
                      : 'bg-red3 text-red4'
                  }  ${StatusItemClassName}`}
                >
                  {getStatusLabel(subscription?.status ?? 'inactive')}
                </div>
                {subscription &&
                  subscription.status !== StripeStatus.PENDING &&
                  subscription.expDate && (
                    <div className="flex items-center justify-end gap-[5px]">
                      <div className="text-end text-[10px] md:text-sm">
                        <div className="text-gray-400">Active before</div>
                        <div className="">
                          {new Date(subscription?.expDate).toLocaleDateString()}
                        </div>
                      </div>
                      <DateIcon />
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </NavLink>
  );
};

export const FamilyAccountsList: FC = () => {
  const limit = 6;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(limit);

  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const { data } = useGetFamilyAccounts({
    limit: String(limit),
    page: String(page),
    search,
  });

  return (
    <div className="bg-white h-full w-full py-[30px] px-[17px] md:py-[30px] lg:px-[130px] flex flex-col gap-[20px] md:gap-[30px]">
      <div className="flex flex-col md:flex-row gap-[10px] justify-between">
        <h2 className="m-0 text-2xl md:text-4xl">Family Accounts</h2>
        <SearchInput search={search} setSearch={setSearch} />
      </div>
      <div className="w-full">
        <div
          className="hidden md:flex justify-between py-[10px] px-[20px] text-sm text-black1"
          style={{
            borderBottom: '2px solid #B3B9C3',
          }}
        >
          <div className="grow w-1/2">Parent name / email</div>
          <div className="grow w-1/2 flex justify-between">
            <div>Student name / email</div>
            <div className="w-[130px]">Status</div>
          </div>
        </div>
        {data?.parents.map((parent) => (
          <ParentItem key={parent.uid} parent={parent} />
        ))}
      </div>
      <Paginator
        className="p-0"
        first={first}
        rows={rows}
        totalRecords={data?.total}
        onPageChange={(event) => {
          setFirst(event.first);
          setRows(event.rows);
          const newPage = event.first / event.rows + 1;
          setPage(newPage);
        }}
      />
    </div>
  );
};
