import React, { useEffect } from 'react';
import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import copyImage from '../../images/icons/copy-icon.svg';
import { useRegisterContext } from '../../layouts/RegisterLayout/RegisterContext';
import { useGetStudentsList } from './useInvitationLinks';
import { useSnackbar } from '../../contexts/SnackbarContext';
import Copyright from '../../components/Copyright';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useFinishRegistration } from './useFinishRegistration';

export const formatInvitationLink = (linkCode: string) => {
  const location = window.location;
  return `${location.origin}/student/register/${linkCode}/`;
};

const InvitationLinks: React.FC = () => {
  const navigate = useNavigate();
  const { data: students, isLoading: isLoadingStudents } = useGetStudentsList(
    {},
  );

  const finishRegistrationMutation = useFinishRegistration({});

  const { openSnackbar } = useSnackbar();

  const { registrationSteps, updateRegistrationSteps } = useRegisterContext();
  updateRegistrationSteps(9);

  const finishRegistration = () => {
    finishRegistrationMutation.mutate();
    navigate('/parent/home');
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar({
        type: 'success',
        message: `Link copied! Now you can send it to your ${
          students?.length === 1 ? 'child' : 'children'
        }`,
      });
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  useEffect(() => {
    const studentInvites = students?.filter((s) => !s.finishedRegistration);

    if (!isLoadingStudents && !studentInvites?.length) {
      navigate('/parent/home');
    }
  }, [students, isLoadingStudents]);
  const studentInvites = students?.filter((s) => !s.finishedRegistration);

  return (
    <div className="relative md:flex content-wrap md:min-h-screen md:pt-[85px] pb-[80px] px-[50px] md:justify-center flex flex-col grow gap-[30px]">
      <RegistrationProgress registrationSteps={registrationSteps} />
      {isLoadingStudents ? (
        <ProgressSpinner className="mx-auto mt-[130px]" />
      ) : (
        <>
          <h2 className="title my-0">
            Get your {students?.length === 1 ? 'child' : 'children'} started
            with an exclusive invitation link for their account!
          </h2>
          <p className="text-sm md:text-base mt-[-10px] mb-0 font-bold">
            Click "Finish" below to email the invite links to your{' '}
            {students?.length === 1 ? 'student' : 'students'}.
          </p>
          {studentInvites?.map((student, index) => (
            <div className="" key={student.uid}>
              <p className="font-bold text-lg pt-0 pb-5 my-0 break-all">
                {index + 1}. {student.fullName}
              </p>
              <div className="p-0 pr-[10px] pl-[20px] rounded-[10px] text-blue1 bg-yellow1">
                <div className="flex justify-between">
                  <p className="truncate">
                    {formatInvitationLink(student.linkCode)}
                  </p>
                  <img
                    onClick={() =>
                      copyToClipboard(formatInvitationLink(student.linkCode))
                    }
                    src={copyImage}
                    alt="copy"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="register-checkbox-wrap mt-3">
            <div className="flex justify-between previous-next-btn-wrap">
              <Button
                label="Finish"
                onClick={finishRegistration}
                icon="pi pi-arrow-right"
                iconPos="right"
                className="next-btn"
              />
            </div>
          </div>
        </>
      )}
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px]" />
    </div>
  );
};

export default InvitationLinks;
