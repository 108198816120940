import React, { memo, useEffect, useState } from 'react';
import PreviousNextButtons from '../../components/PreviousNextButtons/PreviousNextButtons';
import { Button } from 'primereact/button';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { isDesktop } from 'react-device-detect';
import MotivationGoalBox from '../../components/MotivationGoals/GoalBox';
import { Dustbin } from '../../components/MotivationGoals/Dustbin';
import { useRegisterStudentContext } from '../../layouts/RegisterStudentLayout/RegisterStudentContext';
import {
  useUpdateGrade,
  setLinkExpired,
  useUpdateMotivations,
  useGetMotivations,
} from './useMyMotivation';
import desktopLines from '../../images/motivation-lines-desktop.svg';
import mobileLines from '../../images/motivation-lines-mobile.svg';
import { goals } from './constants';
import Copyright from '../../components/Copyright';
import { usePreview } from 'react-dnd-preview';
import dragIcon from '../../images/icons/drag-icon.svg';
import { getOrdinalSuffix } from '../../components/MindMap/MindMap';
import { fill } from 'lodash';

const GoalPreview = () => {
  const preview = usePreview<{ name: string }>();
  if (isDesktop) {
    return <></>;
  }

  if (!preview.display) {
    return null;
  }

  const { item, style } = preview;

  style['zIndex'] = 1000000;

  const name = item?.name || '';
  return (
    <div className="rounded-[20px]  m-[10px]" style={style}>
      <Button className="goal-item color-black1">
        <img src={dragIcon} alt="dragIcon" className="mr-[10px]" />
        {name}
      </Button>
    </div>
  );
};

const MyMotivation: React.FC = memo(() => {
  const { studentData, goToPrevStep, goToNextStep } =
    useRegisterStudentContext();
  const [gradeLevel, setGradeLevel] = useState<number>(7);
  const [items, setItems] = useState<(string | null)[]>(fill(Array(5), null));

  const { mutation: mutationGrade } = useUpdateGrade({});
  const { mutation: mutationMotivations } = useUpdateMotivations({});
  const { data: motivationsData } = useGetMotivations();

  useEffect(() => {
    setGradeLevel(Number(studentData?.gradeLevel));
  }, [studentData]);

  useEffect(() => {
    if (motivationsData?.motivations?.length) {
      setItems(motivationsData.motivations);
    }
  }, [motivationsData]);

  const handleDrop = (item: string, index: number) => {
    const newItems = [...items];
    newItems[index] = item;
    setItems(newItems);
    mutationMotivations.mutate({
      motivations: newItems,
    });
  };

  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems[index] = null;
    setItems(newItems);
    mutationMotivations.mutate({
      motivations: newItems,
    });
  };

  return (
    <div className="relative pb-[80px] bg-white py-[30px] px-[17px] xl:px-[30px] 2xl:px-[50px] md:pt-[27px] h-full flex-col gap-[40px] flex justify-center">
      <div>
        <h2 className="text-2xl md:text-4xl m-0 text-center font-urbanist">
          Please write your grade in the middle oval
        </h2>
        <p className="text-center color-grey3 font-work-sans text-[16px] md:text-[18px]">
          Then drag and drop the top five goals for this year into the oval
          surrounding the middle circle. Choose wisely! ;)
        </p>
        <div className="goals-wrap mb-[30px]">
          <DndProvider options={HTML5toTouch}>
            <div className="selected-goals relative">
              <img
                src={mobileLines}
                alt="dotted line"
                className="xl:hidden bg-line-mobile"
              />
              <img
                src={desktopLines}
                alt="dotted line"
                className="hidden xl:block bg-line-desktop"
              />
              <div className="w-full flex justify-center mb-[20px]">
                <Dustbin
                  item={items[0]}
                  onDrop={({ name }) => {
                    handleDrop(name, 0);
                  }}
                  onRemove={() => {
                    handleRemove(0);
                  }}
                  dustbinClassName="w-[184px]"
                />
              </div>
              <div className="flex flex-row flex-wrap  justify-center w-full">
                <div className="order-1 w-1/2 pr-2 mb-5 xl:w-auto xl:order-1">
                  <Dustbin
                    item={items[1]}
                    onDrop={({ name }) => {
                      handleDrop(name, 1);
                    }}
                    onRemove={() => {
                      handleRemove(1);
                    }}
                  />
                </div>
                <div className="order-3 text-center mb-5 xl:order-2">
                  <div className="flex flex-row align-middle justify-between mx-[30px] bg-green1 grade-btn ">
                    <Button
                      icon="pi pi-minus"
                      rounded
                      text
                      raised
                      aria-label="Minus"
                      onClick={() => {
                        const newGrade = gradeLevel > 1 ? gradeLevel - 1 : 1;
                        setGradeLevel(newGrade);
                        mutationGrade.mutate({ grade: String(newGrade) });
                      }}
                    />
                    <div className="text-[12px] text-center mx-[10px] pt-[5px]">
                      I am in the <br />
                      <span className="text-[18px]">
                        {gradeLevel
                          ? `${getOrdinalSuffix(gradeLevel)} Grade`
                          : ''}
                      </span>
                    </div>
                    <Button
                      icon="pi pi-plus"
                      rounded
                      text
                      raised
                      aria-label="Plus"
                      onClick={async () => {
                        const newGrade = gradeLevel >= 12 ? 12 : gradeLevel + 1;
                        setGradeLevel(newGrade);
                        mutationGrade.mutate({ grade: String(newGrade) });
                      }}
                    />
                  </div>
                </div>
                <div className="order-2 w-1/2 pl-2 mb-5 xl:w-auto xl:order-3">
                  <Dustbin
                    item={items[2]}
                    onDrop={({ name }) => {
                      handleDrop(name, 2);
                    }}
                    onRemove={() => {
                      handleRemove(2);
                    }}
                  />
                </div>
              </div>
              <div className="w-full flex justify-around mb-[20px] xl:justify-center">
                <div className="order-1 w-1/2 pr-2 mb-5 md:w-auto">
                  <Dustbin
                    item={items[3]}
                    onDrop={({ name }) => {
                      handleDrop(name, 3);
                    }}
                    onRemove={() => {
                      handleRemove(3);
                    }}
                  />
                </div>
                <div className="order-1 w-1/2 pl-2 mb-5 md:w-auto">
                  <Dustbin
                    item={items[4]}
                    onDrop={({ name }) => {
                      handleDrop(name, 4);
                    }}
                    onRemove={() => {
                      handleRemove(4);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="goals-list flex justify-center flex-wrap pt-[30px]">
              {goals?.map?.(({ name, type }, index) => (
                <MotivationGoalBox
                  name={name}
                  isDropped={items.includes(name)}
                  key={index}
                />
              ))}
              <GoalPreview />
            </div>
          </DndProvider>
        </div>

        <PreviousNextButtons
          disableNext={items.includes(null)}
          nextStep={async () => {
            await setLinkExpired();
            goToNextStep();
          }}
          previousStep={async () => {
            goToPrevStep();
          }}
        />
      </div>
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[20px]" />
    </div>
  );
});

export default MyMotivation;
