import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import EmailInput from '../../../components/EmailInput';
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useResetPassword } from './useResetPassword';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { emailValidation } from '../../../types/form';
import Copyright from '../../../components/Copyright';
import { useNavigate, useSearchParams } from 'react-router-dom';

type LoginFormFields = {
  email: string;
};

const schema = yup.object().shape({
  email: emailValidation,
});

function ResetPassword() {
  const { openSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const linkSent = searchParams.get('linkSent');
  const email = searchParams.get('email');
  const [animate, setAnimate] = useState('');
  const navigate = useNavigate();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: email ?? '',
    },
  });
  const { handleSubmit } = formMethods;

  const { mutation } = useResetPassword({
    onSuccess: () => {
      if (linkSent) {
        openSnackbar({
          message: 'The link has been sent to your email.',
          type: 'success',
        });
      } else {
        setAnimate('slide-out-left');
        setTimeout(() => {
          setSearchParams(
            {
              linkSent: 'true',
              email: formMethods.getValues().email,
            },
            { replace: true },
          );
          setAnimate('');
        }, 500);
      }
    },
    onError: (_error) => {
      openSnackbar({
        message: "Email doesn't match. Try again.",
        type: 'error',
      });
    },
  });
  const onSubmit: SubmitHandler<LoginFormFields> = (data) => {
    mutation.mutate({
      email: data.email,
    });
  };

  return (
    <div className="relative content-wrap md:min-h-screen bg-white grow overflow-y-hidden">
      <div
        className={`${animate} md:justify-center flex flex-col grow md:min-h-screen pb-[80px] px-12 py-10`}
      >
        <h2 className="text-4xl">
          {linkSent ? 'The link has been sent to your email' : 'Reset Password'}
        </h2>
        <p className="text-lg pb-8">
          {linkSent
            ? 'Please wait a moment for the email before trying again'
            : 'We will email you a link to reset your password'}
        </p>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-[30px]">
              <EmailInput name="email" placeholder="Email" />
              <div className="flex flex-col-reverse md:flex-row justify-between gap-[20px]">
                <Button
                  link
                  className="p-[12px] px-[40px] flex justify-center text-center text-sm md:text-base w-full md:w-fit md:self-end"
                  onClick={() => navigate('/login')}
                >
                  Log In
                </Button>
                <Button
                  type="submit"
                  className="p-[12px] px-[40px] flex justify-center text-center text-sm md:text-base w-full md:w-fit md:self-end"
                >
                  {linkSent ? 'Send Link Again' : 'Send Link'}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
        <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
      </div>
    </div>
  );
}

export default ResetPassword;
