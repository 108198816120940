import { FC, useState } from 'react';
import NavbarLink from '../NavbarLink';
import { NavButtonClass } from '../NavbarLink/NavbarLink';
import { CONTACT_PAGE_URL } from '../../pages/ManageSubscription/ManageSubscriptions';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../types/UserProfile';
import useAuth from '../../contexts/AuthContext';

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}

export const NavigationMenu: FC<Props> = ({ isMenuOpen, setIsMenuOpen }) => {
  const { user, logout, checkSubscription } = useAuth();
  const navigate = useNavigate();
  const isParent = user?.role === UserRole.PARENT;

  const [openDropdown, setOpenDropdown] = useState(false);

  const studentButtonsData = user?.students?.map?.((s) => {
    let result = s.testResultData?.finalResult;
    if (!result?.isTestResultAvailable) {
      result = s.testResultData?.intermediateResult;
    }

    return {
      name: s.fullName,
      link: `/parent/neuro-test/result/${result?.resultUid}`,
      enabled: !!(result?.isTestResultAvailable && s.isSubscriptionActive),
    };
  });

  const isSubscriptionActive = checkSubscription();

  let userTestResult;
  if (user?.testResultData.intermediateResult?.isTestResultAvailable) {
    userTestResult = user.testResultData.intermediateResult;
  }

  if (user?.testResultData.finalResult?.isTestResultAvailable) {
    userTestResult = user.testResultData.finalResult;
  }

  return (
    <div
      className="fixed top-0 right-0 bottom-0 bg-white w-full max-w-[364px] h-[100vh] z-50 pt-[20px] pb-[120px] md:pb-[30px] px-[10px] flex flex-col justify-between"
      style={{
        transform: isMenuOpen ? 'translateX(0)' : 'translateX(100%)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <div>
        <div className="flex justify-between items-center">
          <h5 className="m-0 text-2xl">Menu</h5>
          <i
            className="pi pi-times text-xl cursor-pointer"
            onClick={() => setIsMenuOpen(false)}
          />
        </div>

        <div
          className="my-[20px] pb-[20px] flex flex-col gap-[10px]"
          style={{ borderBottom: '1px solid #b3b9c3' }}
        >
          <NavbarLink to="/profile">Family Profile</NavbarLink>
          {isParent ? (
            <div>
              <div
                className={`${NavButtonClass} flex justify-between items-center`}
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                History of Tests{' '}
                <i
                  className="pi pi-chevron-down"
                  style={{
                    transition: 'transform 0.3s ease',
                    transform: openDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </div>
              <div
                className="flex flex-col"
                style={{
                  transition: 'height 0.3s ease',
                  overflow: 'hidden',
                  height: openDropdown
                    ? `${(user?.students.length || 1) * 54}px`
                    : '0px',
                }}
              >
                {studentButtonsData?.map?.((button, i) => (
                  <NavbarLink
                    key={i}
                    to={button.link}
                    className=" mt-[10px]"
                    disabled={!button.enabled}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {button.name}
                  </NavbarLink>
                ))}
              </div>
            </div>
          ) : (
            <NavbarLink
              to={`/student/neuro-test/result/${
                userTestResult?.resultUid ?? ''
              }`}
              disabled={!userTestResult || !user?.isSubscriptionActive}
              onClick={() => setIsMenuOpen(false)}
            >
              History of Tests
            </NavbarLink>
          )}
          {(isParent || isSubscriptionActive) && (
            <NavbarLink to="/lms">Connect to LMS</NavbarLink>
          )}
          {isParent && (
            <NavbarLink to="/manage-subscriptions">Subscriptions</NavbarLink>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-[10px]">
        <a className={NavButtonClass} href={CONTACT_PAGE_URL}>
          Support
        </a>
        <NavbarLink to="/change-password">Change Password</NavbarLink>
        <NavbarLink to="/privacy-policy">
          Privacy Policy and Terms of Service
        </NavbarLink>
        <div
          className={NavButtonClass}
          onClick={async () => {
            await logout();
            setIsMenuOpen(false);
            navigate('/login');
          }}
        >
          Logout
        </div>
      </div>
    </div>
  );
};
