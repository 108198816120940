import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import { getHomePath } from '../../utils/getHomePath';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import { ChatWindow } from '../ChatWindow/ChatWindow';
import useSelectedStudent from '../../contexts/SelectedStudentContext';

interface Props {
  children: ReactNode;
}

export const NavbarHome: FC<Props> = ({ children }) => {
  const { user, checkSubscription } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { selectedStudent } = useSelectedStudent();
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpen || isChatOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isMenuOpen, isChatOpen]);

  const isBlurred = isMenuOpen || isChatOpen;

  const isSubscriptionActive = checkSubscription(selectedStudent?.uid);
  const showChatButton = useMemo(() => {
    let show = true;
    if (location.pathname.includes('neuro-test')) {
      show = false;
    }
    return show;
  }, [location.pathname]);
  const chatButtonOnLeft = useMemo(() => {
    return (
      location.pathname.includes('/home') ||
      location.pathname.includes('/assignment/')
    );
  }, [location.pathname]);
  return (
    <div className="relative max-w-[100vw] overflow-y-hidden">
      <div
        className="relative min-h-[100vh] pt-[85px]"
        onClick={() => {
          if (isMenuOpen) setIsMenuOpen(false);
          if (isChatOpen) setIsChatOpen(false);
        }}
        style={
          {
            // filter: isBlurred ? 'blur(30px) ' : 'blur(0)',
            // transition: 'filter 0.3s ease-in-out',
          }
        }
      >
        <nav
          style={{ borderBottom: '1px solid #b3b9c3' }}
          className="fixed top-0 w-full z-20 bg-white py-[15px] px-[17px] md:px-[131px] border-b-grey4 flex justify-between items-center"
        >
          <Link to={getHomePath(user)}>
            <img
              src="/images/uluru-logo/smiling.svg"
              alt=""
              width="50px"
              height="50px"
            />
          </Link>
          <i
            className="pi pi-bars text-blue2 text-xl cursor-pointer"
            onClick={() => setIsMenuOpen(true)}
          />
        </nav>
        <div
          style={{
            backdropFilter: isBlurred
              ? 'blur(30px) opacity(1)'
              : 'blur(30px) opacity(0)',
            background: isBlurred ? 'rgba(194, 194, 194, 0.50)' : 'transparent',
            transition:
              'background 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: isBlurred ? 'block' : 'none',
            zIndex: 30,
          }}
        ></div>

        <div
          style={{
            pointerEvents: isBlurred ? 'none' : 'auto',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </div>
      </div>
      {isSubscriptionActive &&
        showChatButton &&
        user?.role !== UserRole.PARENT && (
          <button
            className={`fixed z-10 ${
              chatButtonOnLeft
                ? 'left-[15px] md:left-[79px]'
                : 'right-[15px] md:right-[79px]'
            } bottom-[15px] md:bottom-[48px]  border-none bg-transparent cursor-pointer`}
            onClick={() => setIsChatOpen(true)}
          >
            <img
              className="block w-[86px]"
              src="/images/ask-uluru-button.png"
              alt=""
            />
          </button>
        )}
      <NavigationMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      {isSubscriptionActive && (
        <ChatWindow
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
          studentUid={
            user?.role === UserRole.PARENT ? selectedStudent?.uid : user?.uid
          }
        />
      )}
    </div>
  );
};
