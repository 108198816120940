import { httpClient } from '../../api/http/http-client';
import { GCR_CLIENT_ID, GCR_REDIRECT_URL } from '../../constants/environments';
import { ExternalPopup } from '../../components/ExternalPopup/useExternalPopup';
export interface StudentGCRLink {
  fullName: string;
  studentUid: string;
  isLinked: boolean;
  invalid: boolean;
}

export const getGcrLink = async (
  studentUid: string,
): Promise<StudentGCRLink> => {
  return httpClient.get<void, StudentGCRLink>(`/gcr/list/${studentUid}`);
};

export const unlinkGoogleLogin = async (studentUid: string | undefined) => {
  return httpClient.delete<void, void>(`/gcr/unlink/${studentUid}`);
};

export const handleGoogleLogin = async (studentUid: string) => {
  const scope = [
    'email',
    'profile',
    'https://www.googleapis.com/auth/classroom.courses.readonly',
    'https://www.googleapis.com/auth/classroom.topics.readonly',
    'https://www.googleapis.com/auth/classroom.courseworkmaterials.readonly',
    'https://www.googleapis.com/auth/classroom.coursework.students.readonly',
    'https://www.googleapis.com/auth/classroom.course-work.readonly',
    'https://www.googleapis.com/auth/classroom.student-submissions.me.readonly',
    'https://www.googleapis.com/auth/classroom.coursework.me',
    'https://www.googleapis.com/auth/classroom.rosters',
    'https://www.googleapis.com/auth/classroom.rosters.readonly',
    'https://www.googleapis.com/auth/classroom.profile.emails',
    'https://www.googleapis.com/auth/classroom.profile.photos',
  ].join(' ');
  const state = encodeURIComponent(studentUid);
  const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GCR_CLIENT_ID}&redirect_uri=${GCR_REDIRECT_URL}&response_type=code&scope=${encodeURIComponent(
    scope,
  )}&access_type=offline&prompt=consent&state=${state}`;

  return ExternalPopup(url);
};
