import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { StudentWithTestResults } from '../../pages/FamilyAccountPage/useGetFamilyAccount';

const getDurationString = (
  testStartedAt: string,
  testCompletedAt: string,
): string => {
  const startedAt = new Date(testStartedAt);
  const completedAt = new Date(testCompletedAt);
  // Calculate the difference in milliseconds
  const difference = completedAt.getTime() - startedAt.getTime();

  // Check if the difference is positive
  if (difference < 0) {
    // Return a default value or placeholder for invalid durations
    return '0h 0m';
  }

  // Convert to hours and minutes
  const hours = Math.floor(difference / (1000 * 60 * 60));
  const minutes = Math.max(
    1,
    Math.ceil((difference % (1000 * 60 * 60)) / (1000 * 60)),
  );

  // Construct the duration string
  return `${hours}h ${minutes}m`;
};

export const HistoryOfTestsTable: FC<{
  students: StudentWithTestResults[];
}> = ({ students }) => {
  return (
    <div className="">
      <div className="hidden md:grid min-w-full text-sm text-black1 grid-cols-[35%_13%_22%_30%] items-center">
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Student name / email</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Duration</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Intermediate recommendation</div>
        </div>
        <div className="flex border-bottom-grey1 py-[17px] pl-[20px] font-bold h-full">
          <div className="h-fit my-auto">Final recommendation</div>
        </div>

        {students.map((student) => (
          <>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px] whitespace-nowrap">
              <div className="h-fit my-auto">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.fullName}
                </div>
                <div className="text-sm md:text-base text-grey3 break-all">
                  {student.user?.email}
                </div>
              </div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">
                {student.testStartedAt && student.testCompletedAt
                  ? getDurationString(
                      student.testStartedAt,
                      student.testCompletedAt,
                    )
                  : false}
              </div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">
                {student.intermediateTestResultUid ? (
                  <NavLink
                    to={`/admin/neuro-test/result/${student.intermediateTestResultUid}`}
                  >
                    Link
                  </NavLink>
                ) : (
                  <div className="text-grey3">Link</div>
                )}
              </div>
            </div>
            <div className="border-bottom-grey2 h-full flex py-[10px] pl-[20px]">
              <div className="h-fit my-auto">
                {student.finalTestResultUid ? (
                  <NavLink
                    to={`/admin/neuro-test/result/${student.finalTestResultUid}`}
                  >
                    Link
                  </NavLink>
                ) : (
                  <div className="text-grey3">Link</div>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="md:hidden flex flex-col">
        {students.map((student, i) => (
          <div
            key={student.uid}
            className="flex flex-col gap-[7px] p-[15px]"
            style={{
              borderTop: i === 0 ? '1px solid #EDF0F6' : 'none',
              borderBottom: '1px solid #EDF0F6',
            }}
          >
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Student name / email</div>
              <div className="w-1/2">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.fullName}
                </div>
                <div className="text-sm md:text-base text-grey3 break-all">
                  {student.user?.email}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Duration</div>
              <div className="w-1/2">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.testStartedAt && student.testCompletedAt
                    ? getDurationString(
                        student.testStartedAt,
                        student.testCompletedAt,
                      )
                    : false}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">
                Intermediate recommendation
              </div>
              <div className="w-1/2">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.intermediateTestResultUid ? (
                    <NavLink
                      to={`/admin/neuro-test/result/${student.intermediateTestResultUid}`}
                    >
                      Link
                    </NavLink>
                  ) : (
                    <div className="text-grey3 font-light">Link</div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 text-[12px]">Final recommendation</div>
              <div className="w-1/2">
                <div className="text-sm md:text-base text-black1 font-bold break-all">
                  {student.finalTestResultUid ? (
                    <NavLink
                      to={`/admin/neuro-test/result/${student.finalTestResultUid}`}
                    >
                      Link
                    </NavLink>
                  ) : (
                    <div className="text-grey3 font-light">Link</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
