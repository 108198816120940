import { httpClient } from '../../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';

export const checkToken = async (token: string) => {
  return httpClient.get<void, { valid: boolean }>(
    `/auth/change-password/${token}`,
  );
};

export const useCheckToken = ({ token }: { token?: string | null }) => {
  return useQuery(['checkToken', token], () => checkToken(token ?? ''), {
    enabled: !!token,
  });
};
