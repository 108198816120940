import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { isNil, omitBy } from 'lodash';
import { AssignmentTypes } from '../AssignmentTypeBadge/AssignmentTypeBadge';
import { Student } from '../../types/Student';

export enum AssignmentStatus {
  NOT_STARTED = 'not_started',
  PAUSED = 'paused',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  SUBMITTED = 'submitted',
}

export enum AttachmentType {
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  LINK = 'LINK',
  DRIVE_FILE = 'DRIVE_FILE',
  FORM = 'FORM',
}

export interface AttachmentFile {
  attachmentUid: string;
  title: string;
  url: string;
  thumb: string;
  studentUid: string;
  type: AttachmentType;
}

interface Course {
  uid: string;
  studentUid: string;
  student?: Student;
  name: string;
  externalId: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface Assignment {
  activity: string;
  grade: string;
  createdAt: string;
  description: string;
  dueDate: string;
  status: AssignmentStatus;
  studentUid: string;
  subject: string;
  title: string;
  uid: string;
  updatedAt: string;
  gcrUid: string;
  attachments?: AttachmentFile[];
  type: AssignmentTypes;
  suggestionsStatus?: string;
  isGeneral?: boolean;
  courseUid?: string;
  course?: Course;
}

export interface AssignmentsData {
  assignments: Assignment[];
  total: number;
  subjects: string[];
}

export const getAssignments = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<AssignmentsData> => {
  const [
    ,
    sortByDate,
    search,
    subject,
    status,
    studentId,
    limit,
    page,
    excludePastCompleted,
  ] = queryKey;
  const params = {
    sortAscending: sortByDate === 'asc',
    search,
    subject,
    status,
    studentUid: studentId,
    limit: Number(limit),
    page: Number(page) - 1,
    excludePastCompleted,
  };
  if (params.status === 'all') {
    delete params.status;
  }

  const filteredParams = omitBy(params, isNil);

  return httpClient.get<void, AssignmentsData>('/assignments', {
    params: filteredParams,
  });
};

export const useGetAssignmentList = ({
  sortByDate,
  search,
  subject,
  status,
  studentId,
  limit,
  page,
  excludePastCompleted,
}: {
  sortByDate?: string;
  search?: string;
  subject?: string;
  status?: string;
  studentId?: string;
  limit?: string;
  page?: string;
  excludePastCompleted?: string;
}) => {
  return useQuery(
    [
      '/assignments',
      sortByDate,
      search,
      subject,
      status,
      studentId,
      limit,
      page,
      excludePastCompleted,
    ],
    getAssignments,
    {
      keepPreviousData: true,
    },
  );
};
