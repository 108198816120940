import React, { FC, useMemo } from 'react';
import useAuth from '../../contexts/AuthContext';
import { useGetStudentMotivations } from './useGetStudentMotivations';

export const getOrdinalSuffix = (number: number) => {
  const j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

interface Props {
  studentId?: string;
}

const ItemClassName =
  'bg-green2 text-green1 p-[10px] py-[10px] xl:py-[18px] text-base md:text-sm xl:text-lg w-[180px] md:w-[150px] xl:w-[225px] rounded-[25px] grow-0 h-fit text-nowrap  text-center z-[3] word-break';

export const MindMap: FC<Props> = ({ studentId }) => {
  const { data } = useGetStudentMotivations({ studentId });
  const { user } = useAuth();
  const grade = useMemo(
    () =>
      user?.students?.find((s) => s.uid === studentId)?.gradeLevel ??
      user?.gradeLevel,
    [user, studentId],
  );

  return data ? (
    <div
      className="relative w-full rounded-[10px] shrink-0 gap-[27px] flex flex-col items-center mt-[30px] md:mt-[140px]"
      style={{
        backgroundImage: `url("/images/pebble-background/mindmap.png")`,
        backgroundPosition: 'top',
        backgroundClip: 'border-box',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'padding-box',
      }}
    >
      <img
        src="/images/mindmap-lines-bg-desktop.png"
        alt=""
        className="block absolute h-[173px] top-[calc(50%-86px)] md:h-[147px] md:top-[calc(50%-65px)] xl:top-[calc(50%-100px)] xl:h-[221px] opacity-70"
      />
      <div className={ItemClassName}>{data.list[0]}</div>
      <div className="flex gap-[27px] w-full">
        <div className="flex justify-center w-[50%] grow shrink">
          <div className={ItemClassName}>{data.list[1]}</div>
        </div>
        <div className="flex justify-center w-[50%] grow shrink">
          <div className={ItemClassName}>{data.list[2]}</div>
        </div>
      </div>
      <div className={`${ItemClassName}  bg-green3 !text-black1`}>
        {grade ? `${getOrdinalSuffix(grade)} Grade` : ''}
      </div>
      <div className="flex gap-[27px]">
        <div className="flex justify-center w-[50%] grow shrink">
          <div className={ItemClassName}>{data.list[3]}</div>
        </div>
        <div className="flex justify-center w-[50%] grow shrink">
          <div className={ItemClassName}>{data.list[4]}</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
