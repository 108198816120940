import React, { FC } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SelectItem } from 'primereact/selectitem';
import SearchInput from '../SearchInput';

interface Props {
  subjectList: SelectItem[];
  subject: string;
  setSubject: (v: string) => void;
  sortBy: string;
  setSortBy: (v: string) => void;
  search?: string;
  setSearch: (v: string) => void;
  status: string;
  setStatus: (v: string) => void;
  onAddAssignmentClick: () => void;
  isSubscriptionActive?: boolean;
}

export const sortByOptions = [
  { label: 'Earliest first', value: 'asc' },
  { label: 'Latest first', value: 'desc' },
];

export const statusList = [
  { label: 'All', value: 'all' },
  { label: 'Completed', value: 'completed' },
  { label: 'Pending', value: 'pending' },
];

export const AssignmentPageFilters: FC<Props> = ({
  subjectList,
  subject,
  setSubject,
  setSortBy,
  sortBy,
  search,
  setSearch,
  status,
  setStatus,
  onAddAssignmentClick,
  isSubscriptionActive,
}) => {
  return (
    <div className="flex w-full flex-wrap md:flex-nowrap justify-between gap-[20px]">
      <div className="mr-auto w-full md:w-fit">
        <SearchInput search={search} setSearch={setSearch} />
      </div>
      <div className="flex gap-[20px] flex-wrap grow md:grow-0 md:shrink md:justify-end">
        <div className="flex items-center gap-[10px] w-full md:w-fit">
          <label
            htmlFor="subject"
            className="text-base text-grey3 whitespace-nowrap hidden 2xl:block"
          >
            Subject:
          </label>
          <Dropdown
            id="subject"
            placeholder="Subject"
            options={subjectList}
            className="w-full md:w-fit lg:min-w-[180px]"
            value={subject}
            onChange={(e) => setSubject(e.value)}
          />
        </div>

        <div className="flex items-center gap-[10px] w-[calc(50%-10px)] md:w-fit">
          <label
            htmlFor="sort-by-date"
            className="text-base text-grey3 whitespace-nowrap hidden 2xl:block"
          >
            Sort by:
          </label>
          <Dropdown
            id="sort-by-date"
            options={sortByOptions}
            className="w-full lg:w-[180px]"
            placeholder="Date"
            value={sortBy}
            onChange={(e) => setSortBy(e.value)}
          />
        </div>
        <div className="flex items-center gap-[10px] w-[calc(50%-10px)] md:w-fit">
          <label
            htmlFor="status"
            className="text-base text-grey3 whitespace-nowrap hidden 2xl:block"
          >
            Status:
          </label>
          <Dropdown
            id="status"
            placeholder="Status"
            options={statusList}
            className="w-full lg:w-[180px]"
            value={status}
            onChange={(e) => setStatus(e.value)}
          />
        </div>
      </div>
      {isSubscriptionActive && (
        <Button
          className="text-base gap-[10px] items-center justify-center hidden lg:flex h-fit shrink-0"
          onClick={onAddAssignmentClick}
        >
          <span>Add Assignment</span>
          <i className="pi pi-plus-circle" />
        </Button>
      )}
    </div>
  );
};
