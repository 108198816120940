import { format, parseISO } from 'date-fns';
import { ChildRelationshipMap } from '../../pages/RegisterParent/useRegisterParent';
import { GendersMap } from '../RegisterStudentForm/RegisterStudentForm';
import React, { FC } from 'react';
import { UserProfile } from '../../types/UserProfile';
import { formatInvitationLink } from '../../pages/InvintationLinks/InvitationLinks';
import copyImage from '../../images/icons/copy-icon.svg';
import { useSnackbar } from '../../contexts/SnackbarContext';

const InfoRow = ({ label, value }: { label: string; value?: string }) => {
  return (
    <div className="flex justify-between text-base">
      <div className="shrink-0">{label}</div>
      <div
        style={{
          flexGrow: 1,
          borderBottom: '1px dotted #b3b9c3',
          height: '17px',
          margin: '0 7px',
          minWidth: '20px',
        }}
      ></div>
      <div className={`text-end break-all`}>{value ?? ''}</div>
    </div>
  );
};

interface Props {
  profileData?: UserProfile;
  hideStudents?: boolean;
}
export const ProfileInfo: FC<Props> = ({ profileData, hideStudents }) => {
  const { openSnackbar } = useSnackbar();
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      openSnackbar({
        type: 'success',
        message: `Link copied! Now you can send it to your ${
          profileData?.students?.length === 1 ? 'child' : 'children'
        }`,
      });
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="flex flex-col gap-[30px]">
      {profileData?.uid && (
        <>
          <InfoRow label="Email" value={profileData?.email} />
          <InfoRow label="First Name" value={profileData?.firstName} />
          <InfoRow label="Last Name" value={profileData?.lastName} />
          <InfoRow
            label="Date of Birth"
            value={
              profileData?.birthday &&
              format(parseISO(profileData?.birthday), 'MM-dd-yyyy')
            }
          />
          <InfoRow
            label="Relationship to Child"
            value={
              profileData?.childRelationship &&
              (ChildRelationshipMap[profileData?.childRelationship] ||
                profileData.childRelationship)
            }
          />
          <h4 className="text-2xl m-0">Address</h4>
          <InfoRow label="Country" value={profileData?.country} />
          <InfoRow label="State" value={profileData?.state} />
          <InfoRow label="Street" value={profileData?.street} />
          <InfoRow label="Street 2" value={profileData?.street2} />
          <InfoRow label="Zip Code" value={profileData?.zipCode} />
          <InfoRow label="Phone Number" value={profileData?.phone} />
        </>
      )}
      {!hideStudents && (
        <>
          <h3 className="m-0 mt-[20px] text-2xl md:text-4xl text-header-color">
            Student
            {profileData?.students && profileData.students.length > 1
              ? 's'
              : ''}{' '}
            Info
          </h3>
          {profileData?.students?.map((student, index) => (
            <div key={student.uid}>
              <div className="flex flex-col gap-[30px]">
                <div
                  className="text-sm md:text-base text-grey1 w-full border-grey1 flex justify-between"
                  style={{
                    borderBottom: profileData?.uid ? '1px solid #b3b9c3' : '',
                  }}
                >
                  {profileData?.uid && (
                    <div
                      className="flex gap-[10px] w-fit text-header-color py-[10px] px-[20px]"
                      style={{ borderBottom: '2px solid #468ed0' }}
                    >
                      Student {index + 1}
                    </div>
                  )}
                </div>
                <InfoRow label="Student's full name" value={student.fullName} />
                <InfoRow label="Gender" value={GendersMap[student.gender]} />
                <InfoRow
                  label="Student's date of birth"
                  value={
                    student?.birthday &&
                    format(parseISO(student?.birthday), 'MM-dd-yyyy')
                  }
                />
                <InfoRow label="Student's email" value={student.email} />
                <h4 className="text-2xl m-0">School Information</h4>
                <InfoRow label="Zip code" value={student.schoolZipCode} />
                <InfoRow label="School name" value={student.schoolName} />
                <InfoRow
                  label="Grade level"
                  value={String(student.gradeLevel)}
                />
              </div>
              {!student.linkCodeExpired && student.linkCode && (
                <div className="flex justify-between items-center text-base mt-[45px]">
                  <div className="shrink-0 h-fit mr-[20px]">
                    Invitation Link
                  </div>

                  <p
                    className="truncate m-0 text-blue1 h-fit ml-auto mr-[5px] cursor-pointer"
                    onClick={() =>
                      copyToClipboard(formatInvitationLink(student.linkCode))
                    }
                  >
                    {formatInvitationLink(student.linkCode)}
                  </p>
                  <img
                    onClick={() =>
                      copyToClipboard(formatInvitationLink(student.linkCode))
                    }
                    src={copyImage}
                    alt="copy"
                    className="cursor-pointer"
                  />
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
