import { Outlet } from 'react-router';
import useAuth from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../types/UserProfile';
import NavbarHome from '../../components/NavbarHome';
import { useEffect } from 'react';
import usePushNotifications from '../../hooks/usePushNotifications';

export const StudentLayout = () => {
  usePushNotifications();
  const { user, isLoading: isLoadingUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingUser && !user) navigate('/login');
    if (!isLoadingUser && user?.role === UserRole.PARENT) {
      navigate('/parent/home');
    }
  }, [isLoadingUser]);

  return (
    <NavbarHome>
      <Outlet />
    </NavbarHome>
  );
};
