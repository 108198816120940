import React, { FC, useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ProfileInfo from '../../components/ProfileInfo';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetFamilyAccount } from './useGetFamilyAccount';
import { ProgressSpinner } from 'primereact/progressspinner';
import { UserRole } from '../../types/UserProfile';
import { getHomePath } from '../../utils/getHomePath';
import useAuth from '../../contexts/AuthContext';
import StudentsTable from 'src/app/components/StudentsTable';
import CheckupSurveyResponses from '../../components/CheckupSurveyResponses';
import HistoryOfTestsTable from '../../components/HistoryOfTestsTable';

const tabs = [
  {
    label: 'Parent info',
    value: 'parent-info',
  },
  {
    label: 'Checkup survey responses',
    value: 'checkup-survey-responses',
  },
  {
    label: 'Students info',
    value: 'students-info',
  },
  {
    label: 'History of tests',
    value: 'history-of-tests',
  },
];

export const FamilyAccountPage: FC = () => {
  const { user } = useAuth();
  const { parentUid } = useParams<{ parentUid: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    searchParams.get('selectedTab') ?? tabs[0].value,
  );

  const { data, isLoading } = useGetFamilyAccount({ parentUid });

  useEffect(() => {
    const tabParam = searchParams.get('selectedTab');
    if (selectedTab === tabs[0].value && !tabParam) {
      return;
    }

    if (tabParam !== selectedTab) {
      setSearchParams({ selectedTab });
    }
  }, [selectedTab, setSelectedTab, searchParams]);

  useEffect(() => {
    const tabParam = searchParams.get('selectedTab');
    if (tabParam !== selectedTab) {
      setSelectedTab(searchParams.get('selectedTab') ?? tabs[0].value);
    }
  }, [searchParams, selectedTab]);

  if (isLoading) {
    return (
      <div className="w-full flex">
        <ProgressSpinner className="m-auto" />
      </div>
    );
  }

  return (
    <div className="bg-grey6 w-full">
      <div className="flex flex-col pt-[30px] md:px-[30px] lg:px-[130px] gap-[10px]">
        <div className="h-fit my-auto flex flex-col px-[17px] md:px-0 md:flex-row gap-[20px] justify-between">
          <div className="flex md:flex-col items-center md:items-start gap-[30px] md:gap-0">
            <div className="text-[10px] md:text-sm">Parent name / email</div>
            <div>
              <div className="text-sm md:text-base text-black1 font-bold break-all">
                {data?.firstName + ' '}
                {data?.lastName}
              </div>
              <div className="text-sm md:text-base text-grey3 break-all">
                {data?.user?.email}
              </div>
            </div>
          </div>
          {data?.user?.verified ? (
            <div className="bg-green4 text-green5 text-sm md:text-base h-fit px-[10px] py-[7px] rounded-[10px] w-fit">
              Verified
            </div>
          ) : (
            <div className="bg-red3 text-red4 text-sm md:text-base h-fit px-[10px] py-[7px] rounded-[10px] w-fit">
              Not Verified
            </div>
          )}
        </div>
        <div className="flex overflow-x-auto">
          {tabs.map((tab) => (
            <div
              key={tab.value}
              className={`${
                tab.value === selectedTab ? 'text-black1' : 'text-grey1'
              } text-sm md:text-base max-w-[155px] w-full md:max-w-[293px] py-[17px] px-[10px] md:px-[20px] shrink md:whitespace-nowrap cursor-pointer flex`}
              style={{
                borderBottom:
                  tab.value === selectedTab ? '3px solid #468ED0' : 'none',
              }}
              onClick={() => setSelectedTab(tab.value)}
            >
              <div className="h-fit my-auto">{tab.label}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white h-full w-full py-[30px] px-[17px] md:py-[30px] lg:px-[130px] flex flex-col gap-[20px] md:gap-[30px]">
        <Breadcrumbs
          className=""
          values={[
            {
              label: 'Family Accounts',
              href: getHomePath(user),
            },
            { label: data?.firstName + ' ' + data?.lastName, href: '#' },
          ]}
        />
        {selectedTab === 'parent-info' && (
          <>
            <h3 className="m-0 text-2xl md:text-4xl text-header-color">
              Parent info
            </h3>
            <ProfileInfo
              hideStudents
              profileData={{
                uid: data?.uid ?? '',
                role: UserRole.PARENT,
                email: data?.user?.email ?? '',
                firstName: data?.firstName ?? '',
                lastName: data?.lastName ?? '',
                birthday: data?.birthday ?? '',
                childRelationship: data?.childRelationship ?? '',
                country: data?.country ?? '',
                state: data?.state ?? '',
                street: data?.street ?? '',
                street2: data?.street2 ?? '',
                zipCode: data?.zipCode ?? '',
                phone: data?.phone ?? '',
                students: [],
                createdAt: data?.createdAt ?? '',
                updatedAt: data?.updatedAt ?? '',
                testResultData: {},
              }}
            />
          </>
        )}
        {selectedTab === 'checkup-survey-responses' && (
          <>
            <h3 className="m-0 text-2xl md:text-4xl text-header-color">
              Checkup survey responses
            </h3>
            <CheckupSurveyResponses surveys={data?.surveys || []} />
          </>
        )}
        {selectedTab === 'students-info' && (
          <StudentsTable students={data?.students || []} />
        )}
        {selectedTab === 'history-of-tests' && (
          <>
            <h3 className="m-0 text-2xl md:text-4xl text-header-color">
              History of tests
            </h3>
            <HistoryOfTestsTable students={data?.students || []} />
          </>
        )}
      </div>
    </div>
  );
};
