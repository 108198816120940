import { FormProvider, useForm } from 'react-hook-form';
import EmailInput from '../../../components/EmailInput';
import Input from '../../../components/Input';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { CheckboxInput } from '../../../components/CheckboxInput/CheckboxInput';
import { useLogin, useVerifyUser } from './useLogin';
import useAuth from '../../../contexts/AuthContext';
import { credentialsService } from '../../../utils/services/credentialsService';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailValidation, passwordValidation } from '../../../types/form';
import Copyright from '../../../components/Copyright';
import { getHomePath } from '../../../utils/getHomePath';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { AxiosError } from 'axios/index';

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  rememberMe: yup.boolean(),
});

function Login() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const { handleNetworkError } = useSnackbar();

  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loadUser } = useAuth();
  const navigate = useNavigate();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rememberMe: false,
    },
  });
  const { handleSubmit } = formMethods;

  const { mutation: loginMutation } = useLogin({
    onSuccess: (data) => {
      const rememberMe = formMethods.getValues('rememberMe');
      credentialsService.set({ token: data.accessToken }, !rememberMe);
      navigate(getHomePath(data));
      loadUser();
    },
    onError: (error: AxiosError<{ message: string | string[] }>) => {
      if (error?.response?.status === 400) {
        formMethods.setError('email', {
          type: 'manual',
        });
        formMethods.setError('password', {
          type: 'manual',
          message: 'The email or password is incorrect. Please try again',
        });
      } else {
        handleNetworkError(error);
      }
    },
  });

  const { mutation: mutationVerify } = useVerifyUser({
    onSuccess: () => {
      setStatusMessage('User verified successfully');
      setIsSuccess(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (code && !isLoading) {
      setIsLoading(true);
      mutationVerify.mutate({
        code: code,
      });
    }
  }, [code, isLoading, mutationVerify]);

  return (
    <div>
      <div className="pt-0 md:pt-20 p-20">
        <h2 className="text-2xl md:text-4xl text-center md:text-start my-[30px]">
          Welcome Back!
        </h2>
        {statusMessage && (
          <div
            className={`mt-2 mb-8 p-2 rounded ${
              isSuccess
                ? 'bg-blue-200 text-blue-700'
                : 'bg-red-200 text-red-700'
            }`}
          >
            {statusMessage}
          </div>
        )}
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit((data) => {
              loginMutation.mutate({
                email: data.email,
                password: data.password,
              });
            })}
          >
            <div className="flex flex-col gap-[40px] mb-[20px]">
              <EmailInput
                name="email"
                placeholder="Your Email"
                inputClassName="bg-yellow1"
              />
              <Input
                name="password"
                inputType="password"
                placeholder="Enter Password"
                inputClassName="bg-yellow1"
              />
            </div>

            <div className="flex justify-end md:mb-[30px] mb-[20px]">
              <Link to="/reset-password">
                <Button
                  type="button"
                  className="p-0 pt-[10px] text-sm md:text-base"
                  link
                >
                  Forgot password?
                </Button>
              </Link>
            </div>

            <div className="flex flex-col md:flex-row justify-between md:items-center items-start">
              <CheckboxInput name={'rememberMe'}>Remember Me</CheckboxInput>
              <Button
                type="submit"
                className="w-full md:w-auto mt-4 md:mt-0 justify-center py-[12px] px-[47px] leading-7 md:text-base text-sm"
              >
                Sign In
              </Button>
            </div>
            <div className="flex justify-center md:justify-end mt-[20px] md:mt-[35px]">
              <Link to="/register">
                <Button
                  type="button"
                  link
                  className="md:pr-0 text-sm md:text-base"
                >
                  Don’t have an account yet?
                </Button>
              </Link>
            </div>
          </form>
        </FormProvider>
      </div>
      <Copyright className="absolute left-0 md:left-[40%] right-0 bottom-0 p-[30px]" />
    </div>
  );
}

export default Login;
