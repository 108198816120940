const AlarmClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={{ display: 'block' }}
    >
      <path
        d="M9.00025 16.625C12.7971 16.625 15.8751 13.547 15.8751 9.75012C15.8751 5.95324 12.7971 2.87524 9.00025 2.87524C5.20337 2.87524 2.125 5.95324 2.125 9.75012C2.125 13.547 5.20337 16.625 9.00025 16.625Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M8.91 5.75775L8.90925 9.88575L11.8241 12.8006M1.5 3.375L4.125 1.5M16.5 3.375L13.875 1.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlarmClockIcon;
