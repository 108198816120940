const ArrowLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 12H5"
      stroke="#468ED0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5L5 12L12 19"
      stroke="#468ED0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowLeft;
