import React, { forwardRef } from 'react';
import { ResultBlock } from '../ResultBlock/ResultBlock';
import logo from '../../images/logo.png';
import { processText } from '../../pages/TestResult/TestResult';
import { ResultItem } from '../../pages/TestResult/useGetTestResult';

interface Props {
  resultItems: ResultItem[];
  isFinalRecommendation?: boolean;
  summary?: {
    title: string;
    description: string;
  };
  studentName?: string;
  studentAge?: string;
  studentGender?: string;
}
export const PdfTestResult = forwardRef<HTMLDivElement, Props>(
  (
    {
      resultItems,
      isFinalRecommendation,
      summary,
      studentGender,
      studentAge,
      studentName,
    },
    ref,
  ) => {
    return (
      <div
        className="bg-white py-0 w-[1850px]"
        ref={ref}
        style={{
          display: 'none',
        }}
        id="pdf-container"
      >
        <div className="pdf-content flex items-center justify-between px-[200px] py-[30px] text-2xl font-bold text-header-color">
          <div className="flex gap-[30px] items-center">
            <img src={logo} className="w-[88px]" alt="" />
            <div>
              {isFinalRecommendation
                ? 'Final recommendation'
                : 'Intermediate Recommendation'}
            </div>
          </div>
          <div>theuluru.com</div>
        </div>
        <div className="pdf-content flex justify-between px-[200px] py-[30px] items-center">
          <div className="flex gap-[20px] items-center">
            <img
              src="/images/default-profile-pic.svg"
              alt=""
              className="w-[48px] h-[48px]"
            />
            <div>
              <div className="text-2xl font-bold text-header-color">
                {studentName}
              </div>
              <div className="text-base">
                {studentAge} Years
                {studentGender ? `, ${studentGender}` : ''}
              </div>
            </div>
          </div>
          <div></div>
        </div>

        <div className="pdf-content px-[200px] pb-[60px]">
          <h2 className="text-4xl text-header-color m-0 mb-[10px]">
            {summary?.title}
          </h2>
          <p className="text-base m-0">{summary?.description}</p>
        </div>

        <div className="flex flex-col gap-[60px]">
          {resultItems.map((r, index) => {
            const parsedText = processText(r.resultText || '');

            return (
              <ResultBlock
                isBlockOdd={!!(index % 2)}
                key={index}
                index={index}
                testName={r.testSubName}
                title={parsedText.title}
                description={parsedText.description}
                forceDesktopView
              />
            );
          })}
        </div>
      </div>
    );
  },
);
