import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputTextarea } from 'primereact/inputtextarea';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}

export const TextareaInput: FC<Props> = (props) => {
  const { name, label, required, maxLength = 1000, minLength = 0 } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();
  return (
    <div className={`${fieldError ? 'error' : ''} form-input-field`}>
      <InputTextarea
        {...register(name)}
        placeholder={label}
        rows={5}
        cols={30}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
      />
      {fieldError && <div className="input-error-text">{message}</div>}
    </div>
  );
};
