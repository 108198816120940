import { Outlet } from 'react-router';
import useAuth from '../../contexts/AuthContext';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserRole } from '../../types/UserProfile';
import { useEffect } from 'react';
import { getHomePath } from '../../utils/getHomePath';
import { Button } from 'primereact/button';

export const AdminLayout = () => {
  const { user, isLoading: isLoadingUser, logout } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoadingUser && !user) navigate('/login');
    if (!isLoadingUser && user?.role !== UserRole.ADMIN) {
      navigate(getHomePath(user));
    }
  }, [isLoadingUser]);

  return (
    <div>
      <header className="flex justify-between px-[30px] py-[10px]">
        <NavLink className="" to={getHomePath(user)}>
          <img
            src="/images/uluru-logo/smiling-glasses.svg"
            className="w-[71px]"
            alt="Uluru logo"
          />
        </NavLink>
        <Button
          text
          className="!text-blue1 text-sm flex gap-[10px] items-center justify-center"
          onClick={async () => {
            await logout();
            navigate('/login');
          }}
        >
          <i className="pi pi-sign-out" />
          <span>Log Out</span>
        </Button>
      </header>
      <Outlet />
    </div>
  );
};
