import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';

interface Props {
  name: string;
  children?: React.ReactNode;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: (e: unknown) => void;
}

export const CheckboxInput: FC<Props> = ({
  name,
  children,
  label,
  disabled = false,
  checked = false,
  onChange = () => {},
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    trigger,
    control,
  } = useFormContext();

  const currentValue = checked || watch(name);

  const onChangeCallback = (e: any) => {
    setValue(name, e.checked);
    onChange(e);
  };

  const onBlur = () => control._options.mode === 'onBlur' && trigger(name);

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();

  return (
    <div className="form-input-field relative">
      <input type="hidden" {...register(name)} />

      <label
        className={`${
          currentValue ? 'selected' : ''
        } flex items-center text-sm md:text-base`}
      >
        <Checkbox
          inputId={name}
          onChange={onChangeCallback}
          onBlur={onBlur}
          checked={currentValue || false}
          className="mr-4"
          disabled={disabled}
        />
        {label && <p className="label-text">{label}</p>}
        {children}
      </label>
      {fieldError && <div className="input-error-text">{message}</div>}
    </div>
  );
};
