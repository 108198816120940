import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { UserProfile } from '../../types/UserProfile';

export const getFamilyProfile = async (): Promise<UserProfile> => {
  return httpClient.get<void, UserProfile>('/family-profile', {});
};

export const useGetFamilyProfile = () => {
  return useQuery(['/family-profile'], getFamilyProfile, {});
};
