import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useRegisterStudentContext } from '../../layouts/RegisterStudentLayout/RegisterStudentContext';
import Copyright from '../../components/Copyright';

export default function WelcomeStudent() {
  const navigate = useNavigate();
  const { goToNextStep } = useRegisterStudentContext();

  return (
    <div className="flex flex-col md:justify-center pt-0 md:pt-[80px] py-[80px] px-[50px] grow">
      <div className="h-[50vw] md:h-full">
        <iframe
          src="https://player.vimeo.com/video/933355949?h=44307cb74c&title=0&byline=0&portrait=0&playsinline=1&autopause=0&app_id=122963"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          title="Welcome Video"
        ></iframe>
      </div>
      <div className="br-20">
        <h2>Welcome to ULURU</h2>
        <p>
          ULURU is currently available for use by middle and high school
          students, ages 11-18, and their parents. We will be expanding to
          elementary school students soon!
        </p>
      </div>
      <div className="flex justify-between previous-next-btn-wrap">
        <Button
          label="Sign In"
          icon="pi pi-arrow-left"
          className="p-button-text previous-btn z-10"
          onClick={() => navigate('/login')}
        />
        <Button
          label="Next"
          onClick={() => goToNextStep()}
          icon="pi pi-arrow-right"
          iconPos="right"
          className="next-btn z-10"
        />
      </div>
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px]" />
    </div>
  );
}
