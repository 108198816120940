import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ResultStatus, TestResultWithStudent } from './useGetTestResult';

export const getTestResult = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<TestResultWithStudent> => {
  const [, uid] = queryKey;
  return httpClient.get<void, TestResultWithStudent>(
    '/tests/result/' + uid,
    {},
  );
};

export const useGetTestResultByUid = ({
  resultUid,
}: {
  resultUid?: string;
}) => {
  const [shouldRefetch, _setShouldRefetch] = useState(true);
  const [keepLoading, setKeepLoading] = useState(true);

  const useQueryResult = useQuery(
    ['/family-profile/tests/result', resultUid],
    getTestResult,
    {
      refetchInterval: keepLoading ? 3000 : false,
      enabled: !!resultUid,
    },
  );

  useEffect(() => {
    if (useQueryResult.data?.status === ResultStatus.READY) {
      setKeepLoading(false);
    }
  }, [useQueryResult.data]);

  return {
    ...useQueryResult,
    isRefetchingResult: shouldRefetch,
  };
};
