const ClickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{ display: 'block' }}
    >
      <path
        d="M10 17.3333V10C10 9.46957 10.2107 8.96086 10.5858 8.58579C10.9609 8.21071 11.4696 8 12 8C12.5304 8 13.0391 8.21071 13.4142 8.58579C13.7893 8.96086 14 9.46957 14 10V17.3333"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 16.666V20.9993C26 25.2333 22.5673 28.666 18.3333 28.666H17.6667C13.4327 28.666 10 25.2333 10 20.9993V16.666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 19.3333V16C14 15.4696 14.2107 14.9609 14.5858 14.5858C14.9609 14.2107 15.4696 14 16 14C16.5304 14 17.0391 14.2107 17.4142 14.5858C17.7893 14.9609 18 15.4696 18 16M18 16V19.3333M18 16C18 15.4696 18.2107 14.9609 18.5858 14.5858C18.9609 14.2107 19.4696 14 20 14C20.5304 14 21.0391 14.2107 21.4142 14.5858C21.7893 14.9609 22 15.4696 22 16M22 16V19.3333M22 16C22 15.4696 22.2107 14.9609 22.5858 14.5858C22.9609 14.2107 23.4696 14 24 14C24.5304 14 25.0391 14.2107 25.4142 14.5858C25.7893 14.9609 26 15.4696 26 16V19.3333"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6673 9.99968C18.6689 8.5726 18.211 7.18293 17.3613 6.03634C16.742 5.19737 15.9342 4.51564 15.003 4.04608C14.0719 3.57652 13.0435 3.33227 12.0007 3.33301C10.9577 3.33216 9.92917 3.57636 8.99791 4.04593C8.06666 4.51549 7.25876 5.19728 6.63932 6.03634C5.78996 7.18304 5.33228 8.57268 5.33399 9.99968"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ClickIcon;
