import React, { FC, useState } from 'react';
import useAuth from '../../contexts/AuthContext';
import TrashIcon from '../../icons/TrashIcon';
import { UserRole } from '../../types/UserProfile';
import { Dialog } from 'primereact/dialog';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import {
  BlackbaudTokenStatus,
  getBlackbaudLink,
  handleBlackbaudLogin,
  unlinkBBLogin,
  useBlackbaud,
} from './useBlackbaud';

export interface BlackbaudProps {
  studentUid: string;
}

export const BlackbaudComponent: FC<BlackbaudProps> = ({ studentUid }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const { data: bbLink, refetch } = useBlackbaud({ studentUid });

  const [unlinkStudentVisibility, setUnlinkStudentVisibility] =
    useState<boolean>(false);

  const bbLogin = async (studentUid: string) => {
    await handleBlackbaudLogin(studentUid);
    const link = await getBlackbaudLink({ queryKey: ['', studentUid] });

    if (!link?.isLinked) {
      openSnackbar({
        type: 'error',
        message: 'The Blackbaud account has not been linked.',
      });
      return;
    }

    openSnackbar({
      type: 'success',
      message: 'The Blackbaud account has been linked successfully.',
    });

    setTimeout(() => {
      if (user?.role === UserRole.STUDENT) navigate('/student/home');
      else navigate('/parent/home');
    }, 1000);
  };

  const unlinkBB = async (studentUid: string) => {
    await unlinkBBLogin(studentUid);
    await refetch();
  };

  const toggleUnlinkPopup = (studentUid: string, open = false) => {
    setUnlinkStudentVisibility(open);
  };

  return (
    <>
      {bbLink?.isLinked &&
        bbLink?.status === BlackbaudTokenStatus.UNVERIFIED && (
          <p>
            Please complete verification process on Blackbaud modal window or
            contact with administrator
          </p>
        )}
      {!bbLink?.isLinked && (
        <button
          type="submit"
          className="p-button green-700 p-component p-[12px] px-[40px] text-center text-sm md:text-base"
          onClick={() => bbLogin(studentUid)}
        >
          Link Blackbaud account
        </button>
      )}
      {bbLink?.isLinked && (
        <>
          <p className={'mt-2'}>
            This Uluru account has been already connected to Blackbaud account.{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              //eslint-disable-next-line no-script-url
              href="javascript:void(0)"
              onClick={(e) => {
                e.preventDefault();
                toggleUnlinkPopup(studentUid, true);
              }}
            >
              <div className={'inline ml-1 relative top-1'}>
                <TrashIcon />
              </div>{' '}
              Unlink
            </a>
          </p>
          <Dialog
            header="Unlink Confirmation"
            visible={unlinkStudentVisibility}
            onHide={() => toggleUnlinkPopup(studentUid, false)}
            blockScroll={true}
            contentStyle={{ overflow: 'auto' }}
          >
            <p className={'text-center'}>
              Are you sure want to unlink the Uluru account from Blackbaud
              account?
            </p>
            <button
              type="button"
              onClick={() => {
                unlinkBB(studentUid);
              }}
              className="p-button p-component float-right p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={() => {
                toggleUnlinkPopup(studentUid, false);
              }}
              className="p-button p-component float-left p-[12px] px-[40px] text-center flex justify-center align-right text-sm md:text-base mt-7"
            >
              Cancel
            </button>
          </Dialog>
        </>
      )}
    </>
  );
};
