import React, { FC, useEffect, useMemo, useState } from 'react';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import { Button } from 'primereact/button';

interface Props {}

export const EMAIL_BANNER_STORAGE_KEY = 'verifyEmailBannerHidden';

export const VerifyEmailBanner: FC<Props> = () => {
  const { user } = useAuth();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const storageData = localStorage.getItem(EMAIL_BANNER_STORAGE_KEY);
    const data = storageData ? JSON.parse(storageData) : {};
    const wasHidden = user?.uid && data[user.uid];
    setIsVisible(
      user?.role === UserRole.PARENT && !user?.verified && !wasHidden,
    );
  }, [user]);

  const onHide = useMemo(() => {
    return () => {
      if (!user?.uid) return;
      const storageData = localStorage.getItem(EMAIL_BANNER_STORAGE_KEY);
      const data = storageData ? JSON.parse(storageData) : {};
      localStorage.setItem(
        EMAIL_BANNER_STORAGE_KEY,
        JSON.stringify({ ...data, [user?.uid]: true }),
      );
      setIsVisible(false);
    };
  }, [user]);

  return isVisible ? (
    <div
      className="bg-yellow3 w-full p-[15px] rounded-[8px] flex gap-[10px] items-center"
      style={{ borderLeft: '8px solid #FFBF00' }}
    >
      <i className="pi pi-exclamation-circle text-white bg-yellow4 p-[10px] rounded-[100%] text-[16px]" />
      <div className="text-black1 text-sm">Please Verify Your Account</div>
      <Button
        className="ml-auto p-[5px] !border-none !bg-transparent !text-grey5"
        text
        rounded
        onClick={onHide}
      >
        <i className="block pi pi-times !text-grey5" />
      </Button>
    </div>
  ) : (
    <></>
  );
};
